.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* offcanvas */
.menu-item-has-children {
  transition: all 0.5s ease-out;
}
.menu-item-has-children ul {
  transition: all 0.5s ease-in;
}
/* offcanvas end*/
.slick-dots li button:before {
  display: none;
}
.slick-dots li {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  display: block;
}
.react-slick-item-portfolio {
  padding: 0 10px;
}
.testimonial-item-padding {
  padding: 0 15px;
}
.team-slider {
  padding: 0 5px;
}
.slick-dots {
  bottom: auto;
  width: auto;
}
.hero-slider ul.slick-dots li.slick-active button::before {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  background: black;
}
.hero-slider .slick-dots {
  display: flex !important;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
.portfolio-2-item:hover .portfolio-overlay {
  left: -200%;
}
/* screenshot area */
.screenshot-item {
  padding: 0 10px !important;
}
.client-slide .item {
  background: none;
}
.client-slide {
  background: #fff;
  border-radius: 10px;
}
.hero-2-content .play {
  z-index: 10;
}
