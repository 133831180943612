/*-----------------------------------------------------------------------------------

    Template Name: Aball - Corporate Business Bootstrap4 HTML5 Template
    Template URI: http://quomodosoft.com/html/aball/
    Description: Aball - Corporate Business Bootstrap4 HTML5 Template
    Author: quomodosoft
    Author URI: http://quomodosoft.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. COMMON
	02. HEADER
    03. HERO
	04. ABOUT US
	05. SERVICE
	06. PORTFOLIO
	07. TESTIMONIAL
	08. OVERVIEW
	09. TEAM
	10. PRICING
	11. ARTICLE
	12. FOOTER
	13. FUN FACTS
	14. REVIEW
	15. UPDATE NEWS
	16. CONTACT US
	17. PROJECT
	18. KEY FEATURE
	19. VIDEO
	20. SCREENSHORT
	21. OUR CASE
	22. SINGLE POST

-----------------------------------------------------------------------------------*/
/*===========================
    1. COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "space_grotesk";
  src: url("../fonts/spacegrotesk-light-webfont.woff2") format("woff2"),
    url("../fonts/spacegrotesk-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "space_grotesk";
  src: url("../fonts/spacegrotesk-regular-webfont.woff2") format("woff2"),
    url("../fonts/spacegrotesk-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "space_grotesk";
  src: url("../fonts/spacegrotesk-medium-webfont.woff2") format("woff2"),
    url("../fonts/spacegrotesk-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "space_grotesk";
  src: url("../fonts/spacegrotesk-semibold-webfont.woff2") format("woff2"),
    url("../fonts/spacegrotesk-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "space_grotesk";
  src: url("../fonts/spacegrotesk-bold-webfont.woff2") format("woff2"),
    url("../fonts/spacegrotesk-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
html,
body {
  height: 100%;
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "space_grotesk";
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #33415c;
  background: #fafafa;
}
body.home-2-bg {
  background: #f5f5f5;
}
body.bg-white {
  background: #fff;
}

img {
  max-width: 100%;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
a:hover {
  color: #ffab4a;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "space_grotesk";
  font-weight: normal;
  color: #000;
  margin: 0px 0 15px;
  line-height: normal;
  font-weight: 700;
}

p {
  margin: 0 0 15px;
}

.section__bg {
  position: absolute;
  left: 0;
  top: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.slick-slide {
  outline: 0;
}

.page-link:focus {
  box-shadow: none;
}

@media only screen and (min-width: 1600px) {
  .custom-container {
    max-width: 1355px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .custom-container-2 {
    max-width: 1330px !important;
  }
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid #ffab4a;
  padding: 0 36px;
  font-size: 16px;
  line-height: 54px;
  border-radius: 3px;
  color: #000;
  cursor: pointer;
  z-index: 5;
  font-weight: 600;
  background-color: #ffab4a;
  font-family: "space_grotesk";
}
.main-btn:hover {
  background-color: transparent;
  color: #ffab4a;
}
.main-btn.main-btn-2 {
  background: #fff;
  border-color: #fff;
}
.main-btn.main-btn-2:hover {
  background-color: transparent;
  color: #fff;
}
.main-btn.main-btn-3 {
  background-position: center;
  background: -webkit-linear-gradient(
    left,
    #fad029 0%,
    #ff564f 50%,
    #fad029 100%
  );
  background: -o-linear-gradient(left, #fad029 0%, #ff564f 50%, #fad029 100%);
  background: linear-gradient(to right, #fad029 0%, #ff564f 50%, #fad029 100%);
  background-size: auto;
  background-size: 200% auto;
  border: 0;
  color: #fff;
  padding: 0 34px;
}
.main-btn.main-btn-3:hover {
  background-position: right center;
}

.section-title {
  padding-bottom: 10px;
}
.section-title .title {
  font-size: 54px;
  padding-top: 17px;
}
@media (max-width: 767px) {
  .section-title .title {
    font-size: 36px;
  }
}
.section-title p {
  padding: 0 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title p {
    padding: 0 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title p {
    padding: 0 0;
  }
}
@media (max-width: 767px) {
  .section-title p {
    padding: 0 0;
  }
}
.section-title.section-title-2 {
  color: #fff;
}
.section-title.section-title-2 .title {
  color: #fff;
}

.section-title-3 span {
  background: linear-gradient(
    rgba(255, 86, 79, 0.15) 0%,
    rgba(250, 208, 41, 0.15) 100%
  );
  display: inline-block;
  line-height: 40px;
  height: 40px;
  padding: 0 22px;
  border-radius: 30px;
  color: #ff564f;
  font-family: "space_grotesk";
  margin-bottom: 33px;
}
.section-title-3 .title {
  font-size: 54px;
  line-height: 62px;
  color: #000;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .section-title-3 .title {
    font-size: 36px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-3 .title {
    font-size: 54px;
    line-height: 62px;
  }
}
.section-title-3.section-title-4 .title {
  color: #fff;
}

.section-title-6 {
  padding-bottom: 22px;
}
.section-title-6 .title {
  font-size: 54px;
  line-height: 62px;
  position: relative;
  display: inline-block;
}
@media (max-width: 767px) {
  .section-title-6 .title {
    font-size: 36px;
  }
}
.section-title-6 .title::before {
  position: absolute;
  content: "";
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background: #ff564f;
}
.section-title-6 .title::after {
  position: absolute;
  content: "";
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background: #ff564f;
}
.section-title-6 span {
  font-weight: 300;
  color: #000;
  display: inline-block;
  padding: 0 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title-6 span {
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 span {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .section-title-6 span {
    padding: 0;
  }
}
.section-title-6.section-white .title {
  color: #fff;
}
.section-title-6.section-white span {
  color: #fff;
}

.section-title-9 .title {
  font-size: 56px;
  line-height: 48px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .section-title-9 .title {
    font-size: 36px;
  }
}
.section-title-9 .text {
  color: #33415c;
  padding: 0 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title-9 .text {
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-9 .text {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .section-title-9 .text {
    padding: 0;
  }
}
.section-title-9.section-white .title {
  color: #fff;
}
.section-title-9.section-white .text {
  color: #fff;
  padding: 0 80px 0 0px;
}
.section-title-9.section-title-12 .title {
  font-size: 54px;
}

/*Loader Css*/
.preloader {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.lds-ellipsis {
  margin: 0 auto;
  position: relative;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 64px;
  text-align: center;
  z-index: 9999;
}
.lds-ellipsis span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffab4a;
  -webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}
.lds-ellipsis span:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}
.lds-ellipsis span:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
}

@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/*===========================
    2.HEADER css 
===========================*/
.header-area.header-2-area .header-nav {
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.15);
  padding: 15px 0;
  top: 51px;
}
@media (max-width: 767px) {
  .header-area.header-2-area .header-nav {
    top: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-area.header-2-area .header-nav {
    top: 51px;
  }
}
.header-area.header-2-area .header-nav.sticky {
  padding: 0;
  background-color: #000;
  top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-2-area .header-nav.sticky {
    padding: 15px 0;
  }
}
@media (max-width: 767px) {
  .header-area.header-2-area .header-nav.sticky {
    padding: 15px 0;
  }
}
.header-area.header-2-area.header-13-area .header-nav {
  top: 15px;
}
.header-area.header-2-area.header-13-area .header-nav.sticky {
  top: 0;
  background: #fff;
}
.header-area.header-2-area.header-13-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  a {
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-2-area.header-13-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    color: #fff;
  }
}
@media (max-width: 767px) {
  .header-area.header-2-area.header-13-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    color: #fff;
  }
}
.header-area.header-2-area.header-13-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li
  a {
  color: #000;
}
.header-area.header-2-area.header-13-area
  .header-nav
  .navigation
  .navbar
  .navbar-toggler
  .toggler-icon {
  background: #000;
}
.header-area.header-5-area .header-nav .navigation .navbar .navbar-btn a {
  position: relative;
  z-index: 5;
  border: 0;
  color: #fff;
  border-radius: 3px;
  line-height: 54px;
  overflow: hidden;
}
.header-area.header-5-area
  .header-nav
  .navigation
  .navbar
  .navbar-btn
  a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #fad029 0%, #ff564f 50%, #fad029 100%);
  background-size: 200% auto;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
.header-area.header-5-area
  .header-nav
  .navigation
  .navbar
  .navbar-btn
  a:hover::before {
  background-position: right center;
}
.header-area.header-6-area .header-nav {
  padding: 0 45px;
  top: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-area.header-6-area .header-nav {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-6-area .header-nav {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .header-area.header-6-area .header-nav {
    padding: 0 0px;
  }
}
.header-area.header-6-area .header-nav.sticky {
  background: #fff;
  top: 0;
  padding: 10px 15px;
}
.header-area.header-6-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  a {
  color: #000;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-area.header-6-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    margin-right: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-6-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    color: #fff;
  }
}
@media (max-width: 767px) {
  .header-area.header-6-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    color: #fff;
  }
}
.header-area.header-6-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li
  a {
  color: #000;
}
.header-area.header-6-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li:hover
  > a {
  color: #ff564f;
}
.header-area.header-6-area .header-nav .navigation .navbar .navbar-btn a {
  background: #ff564f;
  border-color: #ff564f;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}
.header-area.header-6-area .header-nav .navigation .navbar .navbar-btn a:hover {
  background: #564fff;
  border-color: #564fff;
}
.header-area.header-6-area
  .header-nav
  .navigation
  .navbar
  .navbar-toggler
  .toggler-icon {
  background: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-6-area .header-nav .navigation .navbar .navbar-collapse {
    background: #ff564f;
  }
}
@media (max-width: 767px) {
  .header-area.header-6-area .header-nav .navigation .navbar .navbar-collapse {
    background: #ff564f;
  }
}
.header-area.header-14-area .header-nav {
  margin: 0 55px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-14-area .header-nav {
    margin: 0 5px;
  }
}
@media (max-width: 767px) {
  .header-area.header-14-area .header-nav {
    margin: 0 5px;
  }
}
.header-area.header-14-area .header-nav.sticky {
  background: #e49574;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-14-area .header-nav {
    margin: 0 15px;
  }
}
.header-area.header-14-area .header-nav .navigation .navbar .navbar-nav {
  margin-left: 45px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-area.header-14-area .header-nav .navigation .navbar .navbar-nav {
    margin-left: 0;
  }
}
.header-area.header-14-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  a {
  margin-right: 0;
  margin-left: 36px;
  color: #000;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-area.header-14-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    margin-left: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-14-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .header-area.header-14-area
    .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    a {
    margin-left: 0;
  }
}
.header-area.header-14-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li
  a {
  margin-left: 0;
}
.header-area.header-14-area .header-nav .navigation .navbar .navbar-btn a {
  background: #000;
  border-radius: 40px;
  line-height: 54px;
  color: #fff;
  border-color: #000;
}
.header-area.header-14-area
  .header-nav
  .navigation
  .navbar
  .navbar-btn
  a:hover {
  background: #fff;
  color: #000;
  border-color: #fff;
}

.header-top {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.15);
}
@media (max-width: 767px) {
  .header-top {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-top {
    display: block;
  }
}
.header-top .header-top-item {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-top .header-top-item .info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.header-top .header-top-item .info ul li {
  color: #fff;
  margin-right: 50px;
}
.header-top .header-top-item .info ul li:last-child {
  margin-right: 0;
}
.header-top .header-top-item .info ul li img {
  padding-right: 12px;
}
@media (max-width: 767px) {
  .header-top .header-top-item .sicial {
    display: none;
  }
}
.header-top .header-top-item .sicial ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-top .header-top-item .sicial ul li {
  display: inline-block;
}
.header-top .header-top-item .sicial ul li a {
  color: #fff;
  margin-left: 28px;
}
.header-top .header-top-item .sicial ul li a:hover {
  color: #ffab4a;
}

.header-nav {
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: transparent;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav {
    padding: 15px 0;
    top: 0;
  }
}
@media (max-width: 767px) {
  .header-nav {
    padding: 15px 0;
    top: 0;
  }
}
.header-nav.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
  z-index: 999;
  animation: sticky 1.2s;
}
.header-nav.sticky .navbar .navbar-nav .nav-item a {
  line-height: 80px !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav.sticky .navbar .navbar-nav .nav-item a {
    line-height: 40px !important;
  }
}
@media (max-width: 767px) {
  .header-nav.sticky .navbar .navbar-nav .nav-item a {
    line-height: 40px !important;
  }
}
.header-nav.sticky .navbar .navbar-nav .nav-item .sub-menu li a {
  line-height: 2.5 !important;
}
.header-nav .navigation .navbar {
  position: relative;
  padding: 0;
  flex-wrap: inherit;
}
.header-nav .navigation .navbar .country-flag img {
  border: 5px solid #fff;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(60, 110, 203, 0.2);
}
.header-nav .navigation .navbar .navbar-toggler {
  border: 0;
}
.header-nav .navigation .navbar .navbar-toggler .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #fff;
  margin: 5px 0;
  display: block;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.header-nav
  .navigation
  .navbar
  .navbar-toggler.active
  .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}
.header-nav
  .navigation
  .navbar
  .navbar-toggler.active
  .toggler-icon:nth-of-type(2) {
  opacity: 0;
}
.header-nav
  .navigation
  .navbar
  .navbar-toggler.active
  .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-collapse {
    position: absolute;
    top: 128%;
    left: 0;
    width: 100%;
    background-color: #ffab4a;
    z-index: 8;
    padding: 10px 16px;
    box-shadow: 0 26px 48px 0 rgba(0, 0, 0, 0.15);
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-collapse {
    position: absolute;
    top: 128%;
    left: 0;
    width: 100%;
    background-color: #ffab4a;
    z-index: 8;
    padding: 10px 16px;
    box-shadow: 0 26px 48px 0 rgba(0, 0, 0, 0.15);
  }
}
.header-nav .navigation .navbar .navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-nav {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-nav {
    margin-right: 0;
  }
}
.header-nav .navigation .navbar .navbar-nav .nav-item {
  position: relative;
}
.header-nav .navigation .navbar .navbar-nav .nav-item a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  font-family: "space_grotesk";
  text-transform: capitalize;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-right: 36px;
  line-height: 45px;
  padding: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item a > i {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item a > i {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item a {
    padding: 0;
    display: block;
    border: 0;
    margin: 0;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item a {
    padding: 0;
    display: block;
    border: 0;
    margin: 0;
    line-height: 40px;
  }
}
.header-nav .navigation .navbar .navbar-nav .nav-item a span {
  padding-left: 5px;
  font-size: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item a span {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item a span {
    display: none;
  }
}
.header-nav .navigation .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.header-nav .navigation .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: 205px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  list-style-type: none;
  margin: 0;
  padding: 25px 0;
  border-radius: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu {
    position: relative !important;
    width: 100% !important;
    left: 0 !important;
    top: auto !important;
    opacity: 1 !important;
    visibility: visible !important;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu {
    position: relative !important;
    width: 100% !important;
    left: 0 !important;
    top: auto !important;
    opacity: 1 !important;
    visibility: visible !important;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
  }
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li {
  position: relative;
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
  margin-left: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    .sub-menu
    > li
    .sub-menu {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    .sub-menu
    > li
    .sub-menu {
    margin-left: 0;
  }
}
.header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li
  .sub-nav-toggler {
  color: #404040;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li a {
  display: block;
  padding: 0px 20px;
  position: relative;
  color: #33415c;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.2;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li a {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li a {
    padding: 0 20px;
  }
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li a i {
  float: right;
  font-size: 16px;
  margin-top: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li a i {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li a i {
    display: none;
  }
}
.header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li
  a
  .sub-nav-toggler
  i {
  display: inline-block;
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    .sub-menu
    > li
    .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
@media (max-width: 767px) {
  .header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    .sub-menu
    > li
    .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
.header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li:hover
  .sub-menu {
  top: -60%;
  opacity: 1;
  visibility: visible;
}
.header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li:hover
  .sub-nav-toggler {
  color: #ffab4a;
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu > li:hover > a {
  color: #ffab4a;
}
.header-nav .navigation .navbar .navbar-nav .nav-item:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 14px;
    font-size: 16px;
    background: none;
    border: 0;
    color: #fff;
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 14px;
    font-size: 16px;
    background: none;
    border: 0;
    color: #fff;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav .navigation .navbar .navbar-btn {
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  .header-nav .navigation .navbar .navbar-btn {
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header-nav .navigation .navbar .navbar-btn a {
  margin-left: 50px;
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
.header-3-area {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
}
.header-3-area .heraer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-3-area .heraer-item .header-menu-item {
  height: 100px;
  width: 630px;
  background: #ff564f;
  display: flex;
  align-items: center;
  padding-left: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-3-area .heraer-item .header-menu-item {
    width: 550px;
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .header-3-area .heraer-item .header-menu-item {
    width: 100%;
    padding-left: 30px;
  }
}
.header-3-area .heraer-item .header-menu-item .toggle-menu {
  margin-right: 20px;
}
.header-3-area .heraer-item .header-menu-item .header-menu-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-3-area .heraer-item .header-menu-item .header-menu-info ul li {
  display: inline-block;
  margin-left: 60px;
}
.header-3-area .heraer-item .header-menu-item .header-menu-info ul li span {
  color: #fff;
  font-size: 13px;
  font-family: "space_grotesk";
  text-transform: uppercase;
}
.header-3-area .heraer-item .header-menu-item .header-menu-info ul li .title {
  font-size: 18px;
  color: #fff;
  font-family: "space_grotesk";
}
.header-3-area .heraer-item .header-menu-btn {
  margin-right: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-3-area .heraer-item .header-menu-btn {
    margin-right: 30px;
  }
}
.header-3-area .heraer-item .header-menu-btn a {
  line-height: 54px;
  width: 120px;
  background: #ff564f;
  color: #fff;
  border-color: #ff564f;
  border-radius: 0;
  text-transform: uppercase;
  padding: 0;
  height: 54px;
}
.header-3-area .heraer-item .header-menu-btn a:hover {
  background-color: #564fff;
  color: #fff;
  border-color: #564fff;
}
.header-3-area.header-4-area {
  background: #f5f8fd;
}
.header-3-area.header-4-area .heraer-item .header-menu-item {
  background: linear-gradient(90deg, #6249ef 0%, #2895dd 100%);
}
.header-3-area.header-4-area .heraer-item .header-menu-btn a {
  position: relative;
  border: 0;
  z-index: 5;
  color: #fff;
}
.header-3-area.header-4-area .heraer-item .header-menu-btn a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #6249ef 0%, #2895dd 50%, #6249ef 100%);
  background-size: 200% auto;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
.header-3-area.header-4-area .heraer-item .header-menu-btn a:hover::before {
  background-position: right center;
}
.header-3-area.header-8-area .heraer-item .header-menu-item {
  background: #bf141d;
  width: 278px;
}
@media (max-width: 767px) {
  .header-3-area.header-8-area .heraer-item .header-menu-item {
    width: 100%;
  }
}
.header-3-area.header-8-area .heraer-item .header-menu-btn a {
  background: #bf141d;
  border-color: #bf141d;
}
.header-3-area.header-8-area .heraer-item .header-menu-btn a:hover {
  background-color: transparent;
  color: #bf141d;
}
.header-3-area.header-8-area.header-16-area .heraer-item .header-menu-item {
  background: #f6bb38;
}
.header-3-area.header-8-area.header-16-area .heraer-item .header-menu-btn a {
  background: #f6bb38;
  color: #000;
  border-color: #f6bb38;
}
.header-3-area.header-8-area.header-16-area
  .heraer-item
  .header-menu-btn
  a:hover {
  background-color: transparent;
  color: #f6bb38;
}

.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9998;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #232323;
  top: 0;
}
.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_menu {
    display: block;
  }
}
@media (max-width: 767px) {
  .offcanvas_menu {
    display: block;
  }
}
.offcanvas_menu.offcanvas_menu_4 .offcanvas-social ul li a {
  color: #564fff;
}
.offcanvas_menu.offcanvas_menu_4 .offcanvas-social ul li a:hover {
  background: #564fff;
  color: #fff;
  border-color: #564fff;
}
.offcanvas_menu.offcanvas_menu_4 .canvas_close a:hover {
  background: #564fff;
  color: #fff;
  border-color: #564fff;
}
.offcanvas_menu.offcanvas_menu_4 .offcanvas_main_menu li a:hover {
  color: #564fff;
}
.offcanvas_menu.offcanvas_menu_7 .offcanvas-social ul li a {
  color: #2aa38c;
}
.offcanvas_menu.offcanvas_menu_7 .offcanvas-social ul li a:hover {
  background: #2aa38c;
  color: #fff;
  border-color: #2aa38c;
}
.offcanvas_menu.offcanvas_menu_7 .canvas_close a:hover {
  background: #2aa38c;
  color: #fff;
  border-color: #2aa38c;
}
.offcanvas_menu.offcanvas_menu_7 .offcanvas_main_menu li a:hover {
  color: #2aa38c;
}
.offcanvas_menu.offcanvas_menu_16 .offcanvas-social ul li a {
  color: #3863f6;
}
.offcanvas_menu.offcanvas_menu_16 .offcanvas-social ul li a:hover {
  background: #3863f6;
  color: #fff;
  border-color: #3863f6;
}
.offcanvas_menu.offcanvas_menu_16 .canvas_close a:hover {
  background: #3863f6;
  color: #fff;
  border-color: #3863f6;
}
.offcanvas_menu.offcanvas_menu_16 .offcanvas_main_menu li a:hover {
  color: #3863f6;
}

.offcanvas_menu_wrapper {
  width: 290px;
  position: fixed;
  background: #fff;
  z-index: 9999;
  top: 0;
  height: 100vh;
  transition: 0.5s;
  left: 0;
  margin-left: -300px;
  padding: 50px 15px 30px;
  overflow-y: auto;
}
.offcanvas_menu_wrapper.active {
  margin-left: 0;
}
.offcanvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}
.offcanvas_menu_wrapper .header-btn {
  margin-bottom: 30px;
}
.offcanvas_menu_wrapper .header-btn a {
  color: #000;
}
.offcanvas_menu_wrapper .header-btn a:hover {
  color: #000;
}

.offcanvas_main_menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.offcanvas_main_menu li {
  position: relative;
}
.offcanvas_main_menu li:last-child {
  margin: 0;
}
.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}
.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offcanvas_main_menu li a:hover {
  color: #ff564f;
}
.offcanvas_main_menu li ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}

.offcanvas_footer {
  margin-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.offcanvas_footer span a {
  font-size: 14px;
  color: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offcanvas_footer span a:hover {
  color: #ff564f;
}

.canvas_close {
  position: absolute;
  top: 10px;
  right: 13px;
}
.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 32px;
  height: 32px;
  display: block;
  text-align: center;
  line-height: 34px;
  border: 1px solid #ededed;
  border-radius: 50%;
  color: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.canvas_close a:hover {
  background: #ff564f;
  border-color: #ff564f;
  color: #fff;
}

.canvas_open a {
  font-size: 26px;
  width: 50px;
  height: 42px;
  display: block;
  line-height: 39px;
  text-align: center;
  border: 1px solid #232323;
  color: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.canvas_open a:hover {
  color: #ff564f;
  border-color: #ff564f;
}

.offcanvas-social {
  margin-bottom: 40px;
}
.offcanvas-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.offcanvas-social ul li {
  display: inline-block;
}
.offcanvas-social ul li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #ff564f;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: inline-block;
}
.offcanvas-social ul li a:hover {
  background: #ff564f;
  border-color: #ff564f;
  color: #fff;
}

.header-7-area {
  position: absolute;
  left: 0;
  top: 40px;
  right: 0;
  z-index: 99;
  padding: 0 45px;
}
@media (max-width: 767px) {
  .header-7-area {
    padding: 0 5px;
  }
}

.header-7-item .header-logo {
  margin-left: 40px;
}
@media (max-width: 767px) {
  .header-7-item .header-btn {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-7-item .header-btn {
    display: block;
  }
}
.header-7-item .header-btn ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.header-7-item .header-btn ul li {
  display: inline-block;
}
.header-7-item .header-btn ul li a {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.header-7-item .header-btn ul li a.main-btn {
  line-height: 62px;
  background: #fad029;
  color: #000;
  border-color: #fad029;
  padding: 0 30px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 1px;
  margin-left: 36px;
}
.header-7-item .header-btn ul li a.main-btn:hover {
  box-shadow: 0px 0px 40px rgba(250, 208, 41, 0.8);
}

.header-9-area .header-nav .navigation .navbar .navbar-btn a {
  position: relative;
  border: 0;
  z-index: 5;
  color: #fff;
  background: linear-gradient(to right, #dc3daa 0%, #7712c8 50%, #dc3daa 100%);
  background-size: 200% auto;
}
.header-9-area .header-nav .navigation .navbar .navbar-btn a:hover {
  background-position: right center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-9-area .header-nav .navigation .navbar .navbar-collapse {
    background: linear-gradient(90deg, #dc3daa 0%, #7712c8 100%);
  }
}
@media (max-width: 767px) {
  .header-9-area .header-nav .navigation .navbar .navbar-collapse {
    background: linear-gradient(90deg, #dc3daa 0%, #7712c8 100%);
  }
}
.header-9-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li:hover
  > a {
  color: #7712c8;
}

.header-2-area.header-10-area .header-top {
  background: #2aa38c;
}
.header-2-area.header-10-area
  .header-top
  .header-top-item
  .sicial
  ul
  li
  a:hover {
  color: #564fff;
}
.header-2-area.header-10-area .header-nav {
  background: #2aa38c;
  top: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}
@media (max-width: 767px) {
  .header-2-area.header-10-area .header-nav {
    top: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-2-area.header-10-area .header-nav {
    top: 50px;
  }
}
.header-2-area.header-10-area .header-nav.sticky {
  background: #2aa38c;
}
.header-2-area.header-10-area .header-nav .navigation .navbar .navbar-btn a {
  background: #fff;
  color: #2aa38c;
  font-size: 18px;
  font-weight: 600;
  border: 2px solid #fff;
}
.header-2-area.header-10-area
  .header-nav
  .navigation
  .navbar
  .navbar-btn
  a:hover {
  background-color: transparent;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-2-area.header-10-area
    .header-nav
    .navigation
    .navbar
    .navbar-collapse {
    background: #2aa38c;
  }
}
@media (max-width: 767px) {
  .header-2-area.header-10-area
    .header-nav
    .navigation
    .navbar
    .navbar-collapse {
    background: #2aa38c;
  }
}
.header-2-area.header-10-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li:hover
  > a {
  color: #2aa38c;
}
.header-2-area.header-11-area .header-nav .navigation .navbar .navbar-btn a {
  background: #564fff;
}
.header-2-area.header-11-area
  .header-nav
  .navigation
  .navbar
  .navbar-btn
  a:hover {
  background: #2aa38c;
  border-color: #2aa38c;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-2-area.header-11-area
    .header-nav
    .navigation
    .navbar
    .navbar-collapse {
    background: #564fff;
  }
}
@media (max-width: 767px) {
  .header-2-area.header-11-area
    .header-nav
    .navigation
    .navbar
    .navbar-collapse {
    background: #564fff;
  }
}
.header-2-area.header-11-area
  .header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li:hover
  > a {
  color: #564fff;
}
.header-2-area.header-11-area .header-top .sicial ul li a:hover {
  color: #564fff;
}

.header-12-area .header-nav {
  padding: 0 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-12-area .header-nav {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-12-area .header-nav {
    padding: 30px 15px;
  }
}
@media (max-width: 767px) {
  .header-12-area .header-nav {
    padding: 30px 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-12-area .header-nav {
    padding: 30px 10px;
  }
}
.header-12-area .header-nav.sticky {
  background: #2aa38c;
  padding: 20px 0;
}
.header-12-area .header-nav .navigation .navbar .navbar-nav .nav-item a {
  margin-right: 0;
  margin-left: 36px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header-12-area .header-nav .navigation .navbar .navbar-nav .nav-item a {
    margin-left: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-12-area .header-nav .navigation .navbar .navbar-nav .nav-item a {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .header-12-area .header-nav .navigation .navbar .navbar-nav .nav-item a {
    margin-left: 0;
  }
}
.header-12-area .header-nav .navigation .navbar .navbar-btn ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.header-12-area .header-nav .navigation .navbar .navbar-btn ul li {
  display: inline-block;
}
.header-12-area .header-nav .navigation .navbar .navbar-btn ul li a {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.header-12-area .header-nav .navigation .navbar .navbar-btn ul li a.main-btn {
  line-height: 62px;
  background: #fad029;
  color: #000;
  border-color: #fad029;
  padding: 0 30px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 1px;
  margin-left: 36px;
}

/*===========================
    3.HERO css 
===========================*/
.hero-area {
  position: relative;
  padding: 7px 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .hero-area {
    padding-bottom: 100px;
  }
}
.hero-area .section__bg {
  background-image: url(../images/hero-bg.jpg);
}
.hero-area .section__bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.4;
}
.hero-area.hero-2-area {
  padding-bottom: 0;
}
.hero-area.hero-2-area .section__bg {
  background-image: url(../images/hero-bg-2.png);
}
.hero-area.hero-2-area .section__bg::before {
  opacity: 0.65;
}
.hero-area.hero-5-area .section__bg {
  background-image: url(../images/hero-bg-3.jpg);
}
.hero-area.hero-5-area .section__bg::before {
  opacity: 0.6;
}
.hero-area.hero-5-area .hero-social {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-area.hero-5-area .hero-social {
    left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-5-area .hero-social {
    left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-5-area .hero-social {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-area.hero-5-area .hero-social {
    display: none;
  }
}
.hero-area.hero-5-area .hero-social ul.nav {
  display: block;
}
.hero-area.hero-5-area .hero-social ul.nav li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.25);
  display: inline-block;
  border-radius: 50%;
  margin: 7px 0;
}
.hero-area.hero-5-area .scroll-to-down {
  position: absolute;
  left: 30px;
  bottom: 40px;
  z-index: 5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-area.hero-5-area .scroll-to-down {
    bottom: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-5-area .scroll-to-down {
    bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-5-area .scroll-to-down {
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .hero-area.hero-5-area .scroll-to-down {
    display: none;
  }
}
.hero-area.hero-5-area .scroll-to-down a {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}
.hero-area.hero-5-area .hero-2-content {
  padding-bottom: 236px;
  position: relative;
  overflow: visible;
}
.hero-area.hero-5-area .hero-2-content .slick-count {
  position: absolute;
  bottom: 40px;
  left: 0;
}
.hero-area.hero-5-area .hero-2-content .slick-count span {
  font-size: 40px;
  color: #fff;
}
.hero-area.hero-5-area .hero-2-content .slick-count span span {
  font-size: 22px;
}
.hero-area.hero-5-area .hero-2-content .hero-btn {
  position: absolute;
  right: -500px;
  bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-5-area .hero-2-content .hero-btn {
    right: -415px;
  }
}
.hero-area.hero-5-area .hero-2-content .hero-btn a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1px;
}
.hero-area.hero-5-area .hero-2-content .hero-btn a::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 88px;
  border-radius: 5px;
  background: linear-gradient(90deg, #fad029 0%, #ff564f 65%);
}
.hero-area.hero-5-area .hero-thumb {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-area.hero-5-area .hero-thumb {
    width: 44%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-5-area .hero-thumb {
    width: 44%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-5-area .hero-thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-area.hero-5-area .hero-thumb {
    display: none;
  }
}

.hero-content {
  padding-top: 195px;
  padding-bottom: 310px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content {
    padding-bottom: 50px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .hero-content {
    padding-bottom: 30px;
    text-align: center;
  }
}
.hero-content .title {
  color: #fff;
  font-size: 70px;
  font-family: "space_grotesk";
  padding-top: 38px;
  line-height: 74px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content .title {
    font-size: 62px;
  }
}
@media (max-width: 767px) {
  .hero-content .title {
    font-size: 34px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content .title {
    font-size: 50px;
    line-height: 56px;
  }
}
.hero-content > span {
  display: block;
  color: #fff;
  font-size: 20px;
  color: #fff;
  padding-top: 0px;
  padding-bottom: 58px;
}
.hero-content a {
  font-size: 20px;
  font-weight: 600;
}

.hero-2-content {
  padding-top: 290px;
  padding-bottom: 230px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .hero-2-content {
    padding-bottom: 100px;
    padding-top: 200px;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-2-content {
    text-align: left;
  }
}
.hero-2-content > span {
  height: 40px;
  padding: 0 24px;
  display: inline-block;
  backdrop-filter: blur(50px);
  --webkit-backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 30px;
  line-height: 44px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 50px;
}
.hero-2-content .title {
  font-size: 70px;
  line-height: 74px;
  color: #fff;
  margin-bottom: 38px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-2-content .title {
    font-size: 64px;
    line-height: 64px;
  }
}
@media (max-width: 767px) {
  .hero-2-content .title {
    font-size: 35px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-2-content .title {
    font-size: 60px;
    line-height: 58px;
  }
}
.hero-2-content .play {
  font-size: 20px;
  color: #fff;
  padding-left: 130px;
  position: relative;
}
@media (max-width: 767px) {
  .hero-2-content .play {
    padding-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-2-content .play {
    padding-left: 130px;
  }
}
.hero-2-content .play a {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 90px;
  width: 90px;
  line-height: 80px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .hero-2-content .play a {
    position: static;
    transform: translateY(0);
    margin-bottom: 20px;
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-2-content .play a {
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-2-content.hero-11-content {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .hero-2-content.hero-11-content {
    padding-bottom: 40px;
  }
}
.hero-2-content.hero-11-content span {
  margin-bottom: 40px;
}
.hero-2-content.hero-11-content .title {
  font-size: 100px;
  line-height: 105px;
  color: #fff;
  margin-bottom: 21px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-2-content.hero-11-content .title {
    font-size: 80px;
    line-height: 80px;
  }
}
@media (max-width: 767px) {
  .hero-2-content.hero-11-content .title {
    font-size: 44px;
    line-height: 44px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-2-content.hero-11-content .title {
    font-size: 70px;
    line-height: 70px;
  }
}
.hero-2-content.hero-11-content .text {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  padding-right: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-2-content.hero-11-content .text {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .hero-2-content.hero-11-content .text {
    padding-right: 0;
  }
}
.hero-2-content.hero-11-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 27px;
}
.hero-2-content.hero-11-content ul li {
  display: inline-block;
}
.hero-2-content.hero-11-content ul li a {
  background: #564fff;
  color: #fff;
  text-transform: uppercase;
  border-color: #564fff;
  border-radius: 5px;
  line-height: 56px;
}
.hero-2-content.hero-11-content ul li a:hover {
  background: #2aa38c;
  border-color: #2aa38c;
  color: #fff;
}
.hero-2-content.hero-11-content ul li a.main-btn-2 {
  background: #2aa38c;
  border-color: #2aa38c;
  margin-left: 5px;
}
@media (max-width: 767px) {
  .hero-2-content.hero-11-content ul li a.main-btn-2 {
    margin-left: 0;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-2-content.hero-11-content ul li a.main-btn-2 {
    margin-left: 28px;
    margin-top: 0px;
  }
}
.hero-2-content.hero-11-content ul li a.main-btn-2:hover {
  background: #564fff;
  border-color: #564fff;
}

.hero-play-11 {
  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
  margin-right: 170px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-play-11 {
    padding-bottom: 200px;
    margin-top: 0;
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .hero-play-11 {
    padding-bottom: 200px;
    margin-top: 0;
    justify-content: flex-start;
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-play-11 {
    display: block;
  }
}
.hero-play-11 a {
  height: 100px;
  width: 100px;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  font-size: 24px;
  color: #564fff;
  line-height: 100px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
}
.hero-play-11 a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #fff;
  z-index: -1;
  animation: pulse-border-2 2s linear infinite;
}
.hero-play-11 a:hover {
  color: #fff;
  background: #564fff;
}

.hero-slider .slick-dots {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-slider .slick-dots {
    right: 10px;
  }
}
.hero-slider .slick-dots li {
  line-height: 0;
  text-align: center;
}
.hero-slider .slick-dots li button {
  font-size: 0;
  border: 0;
  background: #fff;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  padding: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.hero-slider .slick-dots li button::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}
.hero-slider .slick-dots li.slick-active button {
  height: 18px;
  width: 18px;
  position: relative;
  margin: 6px 0;
  background: linear-gradient(#ff564f 0%, #fad029 100%);
}
.hero-slider .slick-dots li.slick-active button::before {
  opacity: 1;
  background: #000;
}
.hero-slider.hero-5-slide .slick-dots {
  opacity: 0;
}
.hero-slider.hero-11-slider .slick-dots li.slick-active button {
  background: #564fff;
}

.hero-play {
  margin-top: -100px;
  display: flex;
  justify-content: flex-end;
  margin-right: 170px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-play {
    margin-top: 0;
    justify-content: center;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .hero-play {
    margin-top: 0;
    justify-content: center;
    margin-right: 0;
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-play {
    margin-top: 0;
    justify-content: center;
    margin-right: 0;
    display: flex;
  }
}
.hero-play a {
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  background: #fff;
  border-radius: 50%;
  color: #ffab4a;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
}
.hero-play a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #fff;
  z-index: -1;
  animation: pulse-border-2 2s linear infinite;
}
.hero-play a:hover {
  color: #fff;
  background: #ffab4a;
}

@keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
.sub-item-area {
  margin-top: -317px;
  height: 417px;
  padding-top: 80px;
  position: relative;
  z-index: 11;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .sub-item-area {
    margin-top: 20px;
    height: auto;
    margin-bottom: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sub-item-area {
    margin-top: 20px;
    height: auto;
    margin-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sub-item-area {
    margin-top: 20px;
    height: auto;
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .sub-item-area {
    margin-top: 20px;
    height: auto;
    margin-bottom: 100px;
  }
}
.sub-item-area.sub-15-area .sub-item {
  background: #bf141d;
}
.sub-item-area.sub-15-area .sub-item .icon svg path {
  transition: all linear 0.3s;
}
.sub-item-area.sub-15-area .sub-item:hover {
  background: #fff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}
.sub-item-area.sub-15-area .sub-item:hover .icon svg path {
  fill: #bf141d;
}

.sub-item {
  background: #2aa38c;
  padding: 40px 25px 22px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 215px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sub-item {
    padding: 40px 15px 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sub-item {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .sub-item {
    margin-top: 30px;
  }
}
.sub-item .title {
  color: #fff;
  font-family: "space_grotesk";
  font-weight: 700;
  padding-top: 36px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-size: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sub-item .title {
    font-size: 18px;
  }
}
.sub-item span {
  display: inline-block;
  font-weight: 300;
  font-family: "space_grotesk";
  color: #000;
  font-size: 16px;
  padding-bottom: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sub-item span {
    font-size: 14px;
  }
}
.sub-item.active,
.sub-item:hover {
  background: #ffab4a;
  height: 318px;
}
.sub-item.active .title,
.sub-item:hover .title {
  color: #000;
  font-family: "space_grotesk";
  font-weight: 600;
}
.sub-item.active span,
.sub-item:hover span {
  opacity: 1;
}

.sub-2-area {
  padding-top: 75px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sub-2-area {
    padding-bottom: 0;
  }
}
.sub-2-area .sub-box {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 77px;
}

.sub-2-item {
  padding-left: 65px;
  font-family: "space_grotesk";
  position: relative;
}
.sub-2-item img {
  position: absolute;
  left: 0;
  top: -6px;
}
.sub-2-item svg {
  position: absolute;
  left: 0;
  top: -6px;
}
.sub-2-item .title {
  font-size: 22px;
  color: #000;
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sub-2-item.item-3 {
    display: none;
  }
}

.hero-3-area {
  background: #ffe4d9;
  position: relative;
  height: 100%;
}
@media (max-width: 767px) {
  .hero-3-area {
    height: auto;
  }
}
.hero-3-area .hero-thumb {
  position: absolute;
  left: 58px;
  bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-3-area .hero-thumb {
    left: 0;
    width: 45%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-3-area .hero-thumb {
    left: 0;
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-3-area .hero-thumb {
    position: static;
    width: 55%;
  }
}
@media (max-width: 767px) {
  .hero-3-area .hero-thumb {
    position: static;
    width: 55%;
  }
}
.hero-3-area .hero-social {
  position: absolute;
  right: 60px;
  bottom: 60px;
}
.hero-3-area .hero-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.hero-3-area .hero-social ul li {
  display: inline-block;
}
@media (max-width: 767px) {
  .hero-3-area .hero-social ul li {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-3-area .hero-social ul li {
    display: inline-block;
  }
}
.hero-3-area .hero-social ul li a {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 48px;
  border: 1px solid #33415c;
  color: #000;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 3px;
}
.hero-3-area.hero-4-area {
  background: #f5f8fd;
  z-index: 10;
}
.hero-3-area.hero-4-area .hero-thumb {
  right: 140px;
  left: auto;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-3-area.hero-4-area .hero-thumb {
    right: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-3-area.hero-4-area .hero-thumb {
    right: 70px;
  }
}
.hero-3-area.hero-4-area .hero-thumb img {
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-3-area.hero-4-area .hero-thumb img {
    width: 80%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-3-area.hero-4-area .hero-thumb img {
    width: 70%;
  }
}
.hero-3-area.hero-4-area .hero-3-content > span {
  color: #564fff;
}
.hero-3-area.hero-4-area .hero-3-content .title span::before {
  background-image: url(../images/hero-line-3.png);
}
.hero-3-area.hero-4-area .hero-3-content .text::before {
  background: #564fff;
}
.hero-3-area.hero-4-area .hero-3-content ul li a.main-btn {
  position: relative;
  border: 0;
  z-index: 5;
  color: #fff;
}
.hero-3-area.hero-4-area .hero-3-content ul li a.main-btn::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #6249ef 0%, #2895dd 50%, #6249ef 100%);
  background-size: 200% auto;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
.hero-3-area.hero-4-area .hero-3-content ul li a:hover::before {
  background-position: right center;
}

.hero-3-content {
  padding-left: 70px;
  padding-top: 215px;
  padding-bottom: 185px;
  margin-left: -30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-3-content {
    padding-bottom: 100px;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .hero-3-content {
    padding-bottom: 100px;
    margin-left: 0;
    padding-left: 0;
  }
}
.hero-3-content > span {
  font-size: 25px;
  font-family: "space_grotesk";
  color: #ff564f;
  text-transform: uppercase;
  padding-bottom: 4px;
}
.hero-3-content .title {
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 70px;
  line-height: 84px;
  margin-bottom: 35px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-3-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}
@media (max-width: 767px) {
  .hero-3-content .title {
    font-size: 35px;
    line-height: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-3-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}
.hero-3-content .title span {
  position: relative;
  z-index: 5;
}
.hero-3-content .title span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -45px;
  width: 100%;
  height: 100%;
  background-image: url(../images/hero-line-2.png);
  z-index: -1;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .hero-3-content .title span::before {
    bottom: -5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-3-content .title span::before {
    bottom: -45px;
  }
}
.hero-3-content .text {
  position: relative;
  padding-left: 26px;
  color: #000;
  font-size: 19px;
  line-height: 30px;
  font-family: "space_grotesk";
  margin-bottom: 56px;
}
.hero-3-content .text::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 84px;
  background: #ff564f;
}
.hero-3-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.hero-3-content ul li {
  display: inline-block;
}
.hero-3-content ul li a {
  border-radius: 0;
  line-height: 56px;
  padding: 0px;
}
.hero-3-content ul li a img {
  margin-top: -6px;
}
.hero-3-content ul li a.main-btn {
  background: #ff564f;
  border-color: #ff564f;
  color: #fff;
  font-size: 14px;
  font-family: "space_grotesk";
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 166px;
}
@media (max-width: 767px) {
  .hero-3-content ul li a.main-btn {
    width: 134px;
    font-size: 12px;
  }
}
.hero-3-content ul li a.main-btn:hover {
  background: #564fff;
  border-color: #564fff;
}
.hero-3-content ul li a.main-btn.main-btn-2 {
  background: #564fff;
  color: #fff;
  border-color: #564fff;
  margin-left: 10px;
  width: 150px;
}
@media (max-width: 767px) {
  .hero-3-content ul li a.main-btn.main-btn-2 {
    margin-left: 0;
    margin-top: 0px;
    width: 140px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-3-content ul li a.main-btn.main-btn-2 {
    margin-left: 10px;
    margin-top: 0px;
  }
}
.hero-3-content ul li a.main-btn.main-btn-2:hover {
  background: #ff564f;
  border-color: #ff564f;
}

.hero-6-area {
  position: relative;
  background: #fff;
  overflow: hidden;
}
.hero-6-area::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  height: 450px;
  width: 380px;
  background: #f5f5f5;
}

.hero-6-content {
  padding-top: 200px;
}
.hero-6-content span {
  background: rgba(86, 79, 255, 0.1);
  display: inline-block;
  color: #564fff;
  line-height: 40px;
  padding: 0 20px;
  text-transform: uppercase;
  border-radius: 40px;
}
.hero-6-content .title {
  font-size: 70px;
  line-height: 74px;
  margin-top: 34px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-6-content .title {
    font-size: 54px;
    line-height: 64px;
  }
}
@media (max-width: 767px) {
  .hero-6-content .title {
    font-size: 35px;
    line-height: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-6-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}
.hero-6-content .text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  color: #000;
  padding-top: 5px;
  padding-right: 60px;
}
@media (max-width: 767px) {
  .hero-6-content .text {
    padding-right: 0;
  }
}
.hero-6-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 67px;
}
.hero-6-content ul li {
  display: inline-block;
}
@media (max-width: 767px) {
  .hero-6-content ul li {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-6-content ul li {
    display: inline-block;
  }
}
.hero-6-content ul li a {
  background: #ff564f;
  color: #fff;
  text-transform: uppercase;
  border-color: #ff564f;
  border-radius: 0;
  line-height: 56px;
}
@media (max-width: 767px) {
  .hero-6-content ul li a {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-6-content ul li a {
    width: auto;
  }
}
.hero-6-content ul li a:hover {
  background: #564fff;
  border-color: #564fff;
  color: #fff;
}
.hero-6-content ul li a.main-btn-2 {
  background: #564fff;
  border-color: #564fff;
  margin-left: 28px;
}
@media (max-width: 767px) {
  .hero-6-content ul li a.main-btn-2 {
    margin-left: 0;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-6-content ul li a.main-btn-2 {
    margin-left: 28px;
    margin-top: 0px;
  }
}
.hero-6-content ul li a.main-btn-2:hover {
  background: #ff564f;
  border-color: #ff564f;
}

.hero-6-thumb {
  margin-right: 50px;
  padding-top: 130px;
  margin-bottom: 140px;
  position: relative;
}
@media (max-width: 767px) {
  .hero-6-thumb {
    margin-right: 0;
  }
}
.hero-6-thumb .hero-dot {
  position: absolute;
  right: -85px;
  bottom: -85px;
}
@media (max-width: 767px) {
  .hero-6-thumb .hero-dot {
    display: none;
  }
}
.hero-6-thumb .box {
  position: absolute;
  left: -100px;
  bottom: 60px;
  border-radius: 0px;
  background: #564fff;
  height: 160px;
  width: 160px;
  text-align: center;
  padding-top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-6-thumb .box {
    bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-6-thumb .box {
    left: 0;
  }
}
@media (max-width: 767px) {
  .hero-6-thumb .box {
    left: 0;
    bottom: 0;
  }
}
.hero-6-thumb .box .title {
  font-size: 44px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px;
}
.hero-6-thumb .box > span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.hero-7-area {
  position: relative;
  z-index: 10;
  height: 100%;
}
@media (max-width: 767px) {
  .hero-7-area {
    height: auto;
  }
}
.hero-7-area .section__bg {
  position: absolute;
  z-index: -1;
  background: linear-gradient(90deg, #13bf9e 0%, #108971 100%);
}
.hero-7-area .section__bg .hero-shape {
  position: absolute;
  left: 0;
  top: 0;
}
.hero-7-area .section__bg .hero-shape-2 {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
}
.hero-7-area .section__bg .hero-case {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-7-area .section__bg .hero-case {
    width: 70%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-7-area .section__bg .hero-case {
    width: 65%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-7-area .section__bg .hero-case {
    width: 47%;
  }
}
@media (max-width: 767px) {
  .hero-7-area .section__bg .hero-case {
    width: 90%;
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-area .section__bg .hero-case {
    width: 70%;
    display: none;
  }
}
.hero-7-area .section__bg .hero-line {
  position: absolute;
  left: 300px;
  bottom: 0;
  z-index: -1;
}

.hero-7-content {
  padding-top: 220px;
  padding-bottom: 220px;
}
@media (max-width: 767px) {
  .hero-7-content {
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-content {
    text-align: left;
  }
}
.hero-7-content .title {
  font-size: 66px;
  line-height: 72px;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-7-content .title {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .hero-7-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-content .title {
    font-size: 66px;
    line-height: 72px;
  }
}
.hero-7-content .text {
  color: #fff;
  font-size: 20px;
  line-height: 25px;
  padding-right: 40px;
  padding-top: 73px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-7-content .text {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .hero-7-content .text {
    padding-top: 0px;
    padding-right: 0;
    font-size: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-content .text {
    font-size: 15px;
  }
}
.hero-7-content .store-btns {
  margin: 48px 0 0;
  padding: 0;
  list-style: none;
}
.hero-7-content .store-btns li {
  display: inline-block;
}
.hero-7-content .store-btns li a {
  background: #fad029;
  height: 67px;
  display: inline-block;
  padding: 13px 26px 0 56px;
  border-radius: 4px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-7-content .store-btns li a {
    padding: 13px 20px 0 50px;
  }
}
.hero-7-content .store-btns li a img {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.hero-7-content .store-btns li a span {
  display: block;
  font-size: 13px;
  color: #000;
  line-height: 16px;
}
.hero-7-content .store-btns li a b {
  color: #383838;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.hero-7-content .store-btns li a.btn-2 {
  background: #025242;
  margin-left: 16px;
}
@media (max-width: 767px) {
  .hero-7-content .store-btns li a.btn-2 {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-content .store-btns li a.btn-2 {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.hero-7-content .store-btns li a.btn-2 span {
  color: #e0e0e0;
}
.hero-7-content .store-btns li a.btn-2 b {
  color: #fff;
}
.hero-7-content .store-btns li a.btn-2:hover {
  box-shadow: 0px 0px 40px rgba(2, 82, 66, 0.8);
}
.hero-7-content .store-btns li a:hover {
  box-shadow: 0px 0px 40px rgba(250, 208, 41, 0.8);
}
.hero-7-content.hero-12-content {
  padding-bottom: 0;
  padding-top: 180px;
}
.hero-7-content.hero-12-content .text {
  padding-top: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-7-content.hero-12-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .hero-7-content.hero-12-content .title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-content.hero-12-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}
.hero-7-content.hero-12-content .laptop {
  margin-bottom: -60px;
}
.hero-7-content.hero-17-content .title {
  padding-right: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-7-content.hero-17-content .title {
    padding-right: 0;
    font-size: 62px;
  }
}
@media (max-width: 767px) {
  .hero-7-content.hero-17-content .title {
    font-size: 36px;
    line-height: 46px;
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-content.hero-17-content .title {
    font-size: 48px;
    line-height: 60px;
    padding-right: 0;
  }
}
.hero-7-content.hero-17-content .text {
  padding-top: 0px;
}
.hero-7-content.hero-17-content .input-box {
  width: 490px;
  position: relative;
}
@media (max-width: 767px) {
  .hero-7-content.hero-17-content .input-box {
    width: auto;
    text-align: left;
  }
}
.hero-7-content.hero-17-content .input-box button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 172px;
  background: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #383838;
}
@media (max-width: 767px) {
  .hero-7-content.hero-17-content .input-box button {
    position: static;
    line-height: 68px;
  }
}
.hero-7-content.hero-17-content .input-box input {
  width: 100%;
  line-height: 68px;
  background: #108971;
  border: 0;
  padding-left: 30px;
  color: #fff;
}
.hero-7-content.hero-17-content .input-box input::placeholder {
  opacity: 1;
  color: #fff;
}
.hero-7-content.hero-17-content .hero-item {
  margin-top: 40px;
}
.hero-7-content.hero-17-content .hero-item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
}
.hero-7-content.hero-17-content .hero-item ul li a {
  color: #fff;
}
.hero-7-content.hero-17-content .hero-item ul li a span {
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 37px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 14px;
  margin-right: 10px;
}
.hero-7-content.hero-17-content .hero-item ul li:last-child {
  margin-left: 40px;
}
@media (max-width: 767px) {
  .hero-7-content.hero-17-content .hero-item ul li:last-child {
    margin-left: 0;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-7-content.hero-17-content .hero-item ul li:last-child {
    margin-left: 40px;
    margin-top: 0px;
  }
}
.hero-7-content.hero-17-content .hero-item ul li:last-child a {
  display: flex;
  align-items: center;
}
.hero-7-content.hero-17-content .hero-item ul li:last-child a i {
  font-size: 30px;
  padding-right: 10px;
}

.hero-7-area.hero-12-area .hero-social {
  position: absolute;
  left: 30px;
  top: 54%;
  transform: translateY(-50%);
  z-index: 5;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-7-area.hero-12-area .hero-social {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-7-area.hero-12-area .hero-social {
    display: none;
  }
}
.hero-7-area.hero-12-area .hero-social ul.nav {
  display: block;
}
.hero-7-area.hero-12-area .hero-social ul.nav li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  display: inline-block;
  border-radius: 50%;
  margin: 7px 0;
  border: 1px solid #fff;
}
.hero-7-area.hero-12-area .scroll-down {
  position: absolute;
  right: 50px;
  bottom: 50px;
}
.hero-7-area.hero-12-area .scroll-down a {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  color: #e0e0e0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: inline-block;
}
.hero-7-area.hero-17-area .hero-social {
  position: absolute;
  left: 30px;
  top: 54%;
  transform: translateY(-50%);
  z-index: 5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-7-area.hero-17-area .hero-social {
    left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-7-area.hero-17-area .hero-social {
    left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-7-area.hero-17-area .hero-social {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-7-area.hero-17-area .hero-social {
    display: none;
  }
}
.hero-7-area.hero-17-area .hero-social ul.nav {
  display: block;
}
.hero-7-area.hero-17-area .hero-social ul.nav li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  display: inline-block;
  border-radius: 50%;
  margin: 7px 0;
  border: 1px solid #fff;
}
.hero-7-area.hero-17-area .scroll-down {
  position: absolute;
  left: 30px;
  bottom: 50px;
}
.hero-7-area.hero-17-area .scroll-down a {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  color: #e0e0e0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: inline-block;
}

.hero-8-area {
  background: #f0323c;
  padding-top: 220px;
  padding-bottom: 140px;
  overflow: hidden;
  position: relative;
  height: 100%;
}
@media (max-width: 767px) {
  .hero-8-area {
    height: auto;
  }
}
.hero-8-area .hero-social {
  position: absolute;
  left: 30px;
  top: 54%;
  transform: translateY(-50%);
  z-index: 5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-8-area .hero-social {
    left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-8-area .hero-social {
    left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-8-area .hero-social {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-8-area .hero-social {
    display: none;
  }
}
.hero-8-area .hero-social ul.nav {
  display: block;
}
.hero-8-area .hero-social ul.nav li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  display: inline-block;
  border-radius: 50%;
  margin: 7px 0;
  border: 1px solid #fff;
}
.hero-8-area.hero-15-area {
  position: relative;
  z-index: 10;
}
.hero-8-area.hero-15-area::before {
  position: absolute;
  content: "";
  left: 185px;
  top: 0;
  background-image: url(../images/hero-line-15.png);
  height: 340px;
  width: 960px;
  background-size: 100% 100%;
  z-index: -1;
}
.hero-8-area.hero-15-area .scroll-down {
  position: absolute;
  left: 30px;
  bottom: 50px;
}
.hero-8-area.hero-15-area .scroll-down a {
  height: 50px;
  width: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  color: #fff;
  display: 20px;
  background: rgba(255, 255, 255, 0.1);
}
.hero-8-area.hero-15-area .hero-8-content .input-box a {
  background: #fff;
  line-height: 68px;
  width: 220px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  font-family: "space_grotesk";
  display: inline-block;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.hero-8-area.hero-15-area .hero-8-content .input-box a:hover {
  background: #bf141d;
  color: #fff;
}
.hero-8-area.hero-15-area.hero-16-area {
  background: #3863f6;
}
.hero-8-area.hero-15-area.hero-16-area .hero-8-play a {
  color: #3863f6;
}

.hero-8-content {
  position: relative;
  z-index: 6;
}
.hero-8-content .title {
  font-size: 66px;
  line-height: 72px;
  color: #fff;
  margin-bottom: 17px;
}
@media (max-width: 767px) {
  .hero-8-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-8-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}
.hero-8-content span {
  color: #fff;
  font-size: 20px;
  padding-bottom: 57px;
  display: inline-block;
}
.hero-8-content .input-box {
  width: 490px;
  position: relative;
}
@media (max-width: 767px) {
  .hero-8-content .input-box {
    width: 100%;
  }
}
.hero-8-content .input-box input {
  width: 100%;
  background: #bf141d;
  border: 0;
  color: #fff;
  height: 68px;
  padding-left: 25px;
}
.hero-8-content .input-box input::placeholder {
  opacity: 1;
  color: #fff;
}
.hero-8-content .input-box button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 172px;
  background: #fff;
  border: 0;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}
@media (max-width: 767px) {
  .hero-8-content .input-box button {
    position: static;
    height: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-8-content .input-box button {
    position: absolute;
    height: 100%;
  }
}
.hero-8-content .input-box button img {
  padding-left: 5px;
}

.hero-8-play {
  margin-right: 50px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-8-play {
    margin-right: 0;
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .hero-8-play {
    display: none;
  }
}
.hero-8-play a {
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  background: #fff;
  color: #ff564f;
  position: relative;
  z-index: 5;
  display: inline-block;
}
.hero-8-play a::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../images/play-dot.png);
  height: 715px;
  width: 715px;
  z-index: -1;
}

.hero-9-area {
  position: relative;
  z-index: 10;
  height: 100%;
}
@media (max-width: 767px) {
  .hero-9-area {
    height: auto;
  }
}
.hero-9-area .section__bg {
  background: linear-gradient(312deg, #45108a 0%, #10054d 100%);
}
.hero-9-area .hero-thumb {
  position: absolute;
  right: 110px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-9-area .hero-thumb {
    right: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-9-area .hero-thumb {
    right: 50px;
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-9-area .hero-thumb {
    position: static;
    padding-bottom: 200px;
    transform: translateY(0);
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .hero-9-area .hero-thumb {
    position: static;
    padding-bottom: 200px;
    transform: translateY(0);
    width: 100%;
    text-align: center;
  }
}

.hero-9-content {
  padding-top: 240px;
  padding-bottom: 230px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-9-content {
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .hero-9-content {
    padding-bottom: 30px;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-9-content {
    text-align: left;
  }
}
.hero-9-content .title {
  color: #fff;
  font-size: 60px;
  line-height: 70px;
  padding-right: 30px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-9-content .title {
    font-size: 46px;
    line-height: 56px;
  }
}
@media (max-width: 767px) {
  .hero-9-content .title {
    padding-right: 0;
    font-size: 31px;
    line-height: 46px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-9-content .title {
    padding-right: 0;
    font-size: 60px;
    line-height: 70px;
    padding-bottom: 30px;
  }
}
.hero-9-content span {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
}
.hero-9-content a {
  background: #fff;
  border-radius: 5px;
  color: #383838;
  border-color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 68px;
  padding: 0 30px;
  margin-top: 50px;
}
.hero-9-content a:hover {
  color: #fff;
}

.hero-3-area.hero-10-area {
  background: #f5f8fd;
}
.hero-3-area.hero-10-area .hero-thumb-10 {
  position: absolute;
  right: 120px;
  bottom: 0;
  width: 43%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .hero-3-area.hero-10-area .hero-thumb-10 {
    right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-3-area.hero-10-area .hero-thumb-10 {
    right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-3-area.hero-10-area .hero-thumb-10 {
    position: static;
    width: 100%;
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .hero-3-area.hero-10-area .hero-thumb-10 {
    position: static;
    width: 100%;
    padding-top: 50px;
  }
}

.hero-3-content.hero-10-content {
  padding-top: 295px;
  padding-bottom: 110px;
  padding-left: 0;
  padding-right: 35px;
  margin-left: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-3-content.hero-10-content {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-3-content.hero-10-content {
    padding-bottom: 0;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .hero-3-content.hero-10-content {
    padding-bottom: 0;
    padding-right: 0;
  }
}
.hero-3-content.hero-10-content > span {
  color: #2aa38c;
}
.hero-3-content.hero-10-content .title span::before {
  background-image: url(../images/hero-line-6.png);
}
.hero-3-content.hero-10-content .text::before {
  background: #2aa38c;
}
.hero-3-content.hero-10-content ul li a {
  background: #2aa38c;
  border-color: #2aa38c;
}
.hero-3-content.hero-10-content ul li a:hover {
  background: #564fff;
  border-color: #564fff;
}
.hero-3-content.hero-10-content ul li a.main-btn-2 {
  background: #564fff;
  border-color: #564fff;
}
.hero-3-content.hero-10-content ul li a.main-btn-2:hover {
  background: #2aa38c;
  border-color: #2aa38c;
}

.page-title-area {
  position: relative;
  z-index: 10;
}
.page-title-area .section__bg {
  background-image: url(../images/page-bg.jpg);
}
.page-title-area .section__bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.4;
}
.page-title-area.page-title-2-area .section__bg {
  background-image: url(../images/page-bg-2.jpg);
}

.page-title-content {
  padding-top: 210px;
  padding-bottom: 30px;
}
.page-title-content > .title {
  color: #fff;
  font-size: 60px;
  line-height: 70px;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .page-title-content > .title {
    font-size: 46px;
    line-height: 56px;
  }
}
@media (max-width: 767px) {
  .page-title-content > .title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .page-title-content > .title {
    font-size: 46px;
    line-height: 56px;
  }
}
.page-title-content nav {
  display: inline-block;
}
.page-title-content nav ol {
  background-color: transparent;
}
.page-title-content nav ol li {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.page-title-content nav ol li a {
  color: #fff;
}
.page-title-content nav ol li.active {
  color: #fff;
}
.page-title-content .scroll-to-down a {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #e0e0e0;
  color: #383838;
  font-size: 20px;
  border-radius: 50%;
}
.page-title-content.page-title-content-2 {
  padding-top: 200px;
}
.page-title-content.page-title-content-2 .info {
  display: inline-block;
  text-align: left;
  padding-left: 65px;
  position: relative;
  margin-top: 14px;
  margin-bottom: 90px;
}
.page-title-content.page-title-content-2 .info img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.page-title-content.page-title-content-2 .info .title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.page-title-content.page-title-content-2 .info span {
  color: #fff;
  font-size: 14px;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  content: "/";
}

.hero-slider-2 {
  height: 100%;
}

.hero-14-area {
  position: relative;
  z-index: 10;
  height: 100%;
}
@media (max-width: 767px) {
  .hero-14-area {
    height: auto;
  }
}
.hero-14-area .section__bg {
  background-image: url(../images/hero-bg-14.jpg);
  background-size: 100% 100%;
  overflow: hidden;
}
.hero-14-area .section__bg .shape-1 {
  position: absolute;
  left: -30px;
  top: 0;
}
.hero-14-area .section__bg .shape-2 {
  position: absolute;
  right: -30px;
  bottom: 0;
}
.hero-14-area .section__bg .scroll-to-down {
  position: absolute;
  left: 30px;
  bottom: 50px;
}
@media (max-width: 767px) {
  .hero-14-area .section__bg .scroll-to-down {
    display: none;
  }
}
.hero-14-area .section__bg .scroll-to-down a {
  background: rgba(0, 0, 0, 0.1);
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
}
.hero-14-area .section__bg .hero-line {
  position: absolute;
  right: 0;
  top: 80%;
  transform: translateY(-50%);
}
.hero-14-area .section__bg .hero-slider-counter {
  position: absolute;
  right: 30px;
  bottom: 50px;
}
.hero-14-area .section__bg .hero-slider-counter span {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
}
.hero-14-area .section__bg .hero-slider-counter span span {
  font-size: 18px;
}

.hero-14-content {
  padding-top: 290px;
  padding-bottom: 90px;
}
.hero-14-content span {
  font-size: 13px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.15);
  display: inline-block;
  line-height: 40px;
  padding: 0 17px;
  border-radius: 40px;
}
.hero-14-content .title {
  font-size: 66px;
  line-height: 72px;
  padding-right: 60px;
  margin-bottom: 11px;
  padding-top: 23px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-14-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .hero-14-content .title {
    font-size: 32px;
    line-height: 40px;
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-14-content .title {
    font-size: 50px;
    line-height: 60px;
    padding-right: 0;
  }
}
.hero-14-content .text {
  font-size: 20px;
  color: #000;
  padding-bottom: 31px;
}
.hero-14-content > a {
  width: 220px;
  line-height: 60px;
  padding: 0;
  border-radius: 40px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.hero-14-content > a:hover {
  background-color: #000 !important;
  border-color: #000;
  color: #fff;
}
.hero-14-content ul {
  margin-top: 120px;
}
.hero-14-content ul li a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
}

.hero-14-play {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-right: 170px;
}
.hero-14-play a {
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  background: #fff;
  border-radius: 50%;
  color: #a4aab0;
  font-size: 20px;
  position: relative;
  z-index: 10;
}
.hero-14-play a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #fff;
  z-index: -1;
  animation: pulse-border-2 2s linear infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-14-play a {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-14-play a {
    display: none;
  }
}

/*===========================
    4.ABOUT US css 
===========================*/
.about-content {
  margin-right: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-content {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .about-content {
    margin-right: 0;
  }
}
.about-content span {
  display: block;
  font-size: 25px;
  font-family: "space_grotesk";
  text-transform: uppercase;
  color: #000;
  padding-top: 11px;
  padding-bottom: 37px;
  font-weight: 500;
}
.about-content .title {
  font-size: 54px;
  line-height: 62px;
  padding-bottom: 4px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .about-content .title {
    font-size: 32px;
    line-height: 42px;
  }
}
.about-content ul {
  margin: 25px 0 0 15px;
  padding: 0;
  color: #000;
}
.about-content ul li {
  font-size: 17px;
  line-height: 35px;
}
.about-content a {
  margin-top: 33px;
}

.about-thumb {
  margin-left: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-thumb {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-thumb {
    margin-left: 0;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .about-thumb {
    margin-left: 0;
    margin-top: 30px;
  }
}
.about-thumb > img {
  width: 100%;
}
.about-thumb ul {
  margin: -60px 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .about-thumb ul {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-thumb ul {
    display: flex;
  }
}
.about-thumb ul > li {
  text-align: center;
  height: 142px;
  width: 165px;
  box-shadow: 0px 5px 50px 0 rgba(51, 65, 92, 0.05);
  border-radius: 5px;
  padding-top: 25px;
  margin: 0 10px;
  background: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-thumb ul > li {
    width: 150px;
  }
}
@media (max-width: 767px) {
  .about-thumb ul > li {
    width: 150px;
    margin-bottom: 30px;
  }
}
.about-thumb ul > li span {
  display: block;
  font-size: 13px;
  padding-top: 13px;
  margin-bottom: -8px;
}
.about-thumb ul > li i {
  color: #ffab4a;
  font-size: 10px;
}

.about-2-area {
  padding-top: 70px;
  padding-bottom: 100px;
}
.about-2-area.about-14-area .about-2-content a {
  background: #000;
  border-radius: 40px;
}
.about-2-area.about-14-area .about-2-content a:hover {
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
  color: #000;
}
.about-2-area.about-14-area .about-2-thumb .thumb-2 .box {
  background: #000;
}
.about-2-area.about-15-area {
  padding-top: 0;
  margin-top: -30px;
}
.about-2-area.about-15-area .about-2-content span {
  background: #f0323c;
  color: #fff;
}
.about-2-area.about-15-area .about-2-content .item.active::before,
.about-2-area.about-15-area .about-2-content .item:hover::before {
  background: #f0323c;
}
.about-2-area.about-15-area .about-2-content a {
  background: #f0323c;
  color: #fff;
  border: 2px solid #f0323c;
}
.about-2-area.about-15-area .about-2-content a:hover {
  background-color: transparent;
  color: #bf141d;
}
.about-2-area.about-15-area .about-2-thumb .thumb-2 .box {
  background: #f0323c;
}

.about-2-content {
  font-family: "space_grotesk";
  margin-right: -20px;
}
@media (max-width: 767px) {
  .about-2-content {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-2-content {
    margin-right: 0;
  }
}
.about-2-content span {
  background: linear-gradient(
    rgba(255, 86, 79, 0.15) 0%,
    rgba(250, 208, 41, 0.15) 100%
  );
  display: inline-block;
  line-height: 40px;
  height: 40px;
  padding: 0 22px;
  border-radius: 30px;
  color: #ff564f;
  font-family: "space_grotesk";
}
.about-2-content > .title {
  font-size: 54px;
  line-height: 62px;
  padding-right: 0px;
  padding-top: 38px;
  padding-bottom: 22px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .about-2-content > .title {
    padding-right: 0;
    font-size: 32px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-2-content > .title {
    padding-right: 0;
    font-size: 32px;
    line-height: 42px;
  }
}
.about-2-content .item {
  padding-left: 20px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-2-content .item {
    padding-left: 10px;
  }
}
.about-2-content .item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 100px;
  border-radius: 10px;
  background: #e0e0e0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.about-2-content .item .title {
  font-family: "space_grotesk";
  font-size: 25px;
}
.about-2-content .item.active::before,
.about-2-content .item:hover::before {
  background: linear-gradient(#ff564f 0%, #fad029 100%);
}
.about-2-content.about-11-content .title {
  padding-bottom: 7px;
}
.about-2-content.about-11-content .item.active::before,
.about-2-content.about-11-content .item:hover::before {
  background: #564fff;
}
.about-2-content.about-11-content a {
  background: #564fff;
}
.about-2-content.about-11-content a:hover {
  background: #2aa38c;
}

@media (max-width: 767px) {
  .about-2-thumb .thumb img {
    width: 100%;
  }
}
.about-2-thumb .thumb-2 {
  margin-top: -173px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-2-thumb .thumb-2 {
    margin-left: 50px;
    margin-top: -100px;
  }
}
@media (max-width: 767px) {
  .about-2-thumb .thumb-2 {
    margin-top: 0;
    margin-left: 0;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .about-2-thumb .thumb-2 img {
    width: 100%;
  }
}
.about-2-thumb .thumb-2 .box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 90px;
  border-radius: 8px;
  background: linear-gradient(330deg, #ff564f 0%, #fad029 80%);
  height: 160px;
  width: 160px;
  text-align: center;
  padding-top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-2-thumb .thumb-2 .box {
    right: 0;
  }
}
@media (max-width: 767px) {
  .about-2-thumb .thumb-2 .box {
    right: 0;
  }
}
.about-2-thumb .thumb-2 .box .title {
  font-size: 44px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px;
}
.about-2-thumb .thumb-2 .box > span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.about-2-thumb.about-11-thumb .thumb-2 .box {
  background: #564fff;
}

.about-company-area {
  padding-top: 70px;
  padding-bottom: 100px;
  position: relative;
  z-index: 10;
}
.about-company-area .section__bg {
  background-image: url(../images/company-bg.jpg);
}
.about-company-area .section__bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.about-company-content {
  padding: 61px 43px 55px 40px;
  background: #fff;
  border-radius: 5px;
  margin-right: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-company-content {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .about-company-content {
    margin-right: 0;
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-company-content {
    margin-right: 0;
    padding: 61px 43px 55px 40px;
  }
}
.about-company-content .title {
  font-size: 54px;
  line-height: 62px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .about-company-content .title {
    font-size: 36px;
    line-height: 40px;
  }
}
.about-company-content a {
  margin-top: 24px;
}

.company-brand-area {
  margin-left: -25px;
  margin-right: 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .company-brand-area {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .company-brand-area {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }
}
.company-brand-area .company-brand-item {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  height: 150px;
  border-radius: 5px;
  text-align: center;
}
@media (max-width: 767px) {
  .company-brand-area .company-brand-item {
    margin-top: 20px;
  }
}
.company-brand-area .company-brand-item a {
  opacity: 0.2;
}
.company-brand-area .company-brand-item:hover a {
  opacity: 1;
}
.company-brand-area .col-lg-6 {
  padding-left: 10px;
  padding-right: 10px;
}

.about-3-area {
  background: #f5f5f5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-3-thumb {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .about-3-thumb {
    margin-bottom: 30px;
  }
}
.about-3-thumb img {
  width: 100%;
}

.about-3-content {
  margin-left: 75px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-3-content {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-3-content {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .about-3-content {
    margin-left: 0;
  }
}
.about-3-content .content .title {
  font-family: "Playfair Display", serif;
  font-size: 60px;
  font-weight: 700;
}
.about-3-content .content .text {
  font-size: 20px;
  line-height: 30px;
  font-family: "space_grotesk";
  color: #000;
}
.about-3-content .content .text p span {
  font-family: "space_grotesk";
  color: #ff564f;
}
.about-3-content .about-tab {
  margin-top: 40px;
}
.about-3-content .about-tab ul li a {
  padding: 0;
  background-color: transparent;
  color: #000;
  margin-right: 50px;
}
@media (max-width: 767px) {
  .about-3-content .about-tab ul li a {
    margin-right: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-3-content .about-tab ul li a {
    margin-right: 50px;
  }
}
.about-3-content .about-tab ul li a.active {
  background-color: transparent;
  color: #000;
  position: relative;
}
.about-3-content .about-tab ul li a.active::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 3px;
  height: 2px;
  width: 100%;
  background: #ff564f;
}
.about-3-content .about-tab ul li:last-child a {
  margin-right: 0;
}
.about-3-content .about-tab .tab-content .tab-pane .text {
  font-size: 20px;
  line-height: 30px;
  font-family: "space_grotesk";
  color: #000;
  padding-top: 26px;
}
.about-3-content .about-tab .tab-content .tab-pane ul {
  margin: 0 0 0 15px;
  padding: 0;
}
.about-3-content .about-tab .tab-content .tab-pane ul li {
  line-height: 35px;
  color: #000;
}

.brand-3-area {
  background: #fff;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .brand-3-items {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-3-items .item {
    margin: 0 25px;
  }
}
@media (max-width: 767px) {
  .brand-3-items .item {
    margin: 15px 40px;
  }
}
.brand-3-items .item a {
  opacity: 0.5;
}
.brand-3-items .item a:hover {
  opacity: 1;
}

.brand-9-area .brand-3-items .item a img {
  opacity: 0.5;
}

.about-4-area {
  background: #f5f5f5;
}

.about-4-thumb {
  margin-left: -80px;
  margin-right: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-4-thumb {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .about-4-thumb {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.about-4-thumb img {
  width: 100%;
}
.about-4-thumb.about-10-thumb {
  margin-left: -30px;
  margin-right: 0px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-4-thumb.about-10-thumb {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .about-4-thumb.about-10-thumb {
    margin-left: 0;
  }
}
.about-4-thumb.about-10-thumb .title {
  font-size: 60px;
  line-height: 70px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 80px;
  transform: rotate(-45deg);
  font-weight: 600;
}
@media (max-width: 767px) {
  .about-4-thumb.about-10-thumb .title {
    font-size: 30px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-4-thumb.about-10-thumb .title {
    font-size: 60px;
    bottom: 80px;
  }
}

.about-4-content {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}
.about-4-content .title {
  font-size: 60px;
  line-height: 70px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
}
@media (max-width: 767px) {
  .about-4-content .title {
    font-size: 40px;
  }
}
.about-4-content p span {
  font-weight: 500;
  color: #564fff;
}
.about-4-content.about-10-content p span {
  color: #2aa38c;
}
.about-4-content.about-10-content
  .skills
  .skill-item
  .skill-bar
  .bar-inner
  .bar {
  background: #2aa38c;
}

.skills {
  position: relative;
  padding-top: 27px;
}

.skills .skill-item {
  position: relative;
  margin-bottom: 46px;
}

.skills .skill-item .skill-header {
  position: relative;
  margin-bottom: 10px;
}

.skills .skill-item .skill-header .skill-percentage {
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.skills .skill-item .skill-header .skill-percentage .count-box {
  font-size: 15px;
  font-weight: 400;
}

.skills .skill-item .skill-header .skill-percentage .count-box span {
  font-weight: 400;
  font-size: 14px;
}

.skills .skill-item .skill-header .skill-title {
  font-size: 17px;
  font-weight: 400;
  color: #000;
  margin-bottom: 2px;
}

.skills .skill-item:last-child {
  margin-bottom: 0;
}

.skills .skill-item .skill-bar {
  position: relative;
  width: 100%;
}

.skills .skill-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-radius: 0px;
}

.skills .skill-item .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0;
  top: 0px;
  width: 0;
  height: 8px;
  background: linear-gradient(90deg, #6249ef 0%, #2895dd 100%);
  -webkit-transition: all 2000ms ease;
  transition: all 2000ms ease;
  border-radius: 0px;
  margin-top: -3px;
}

.about-5-area {
  padding-top: 70px;
  padding-bottom: 100px;
  background: #f5f5f5;
}
.about-5-area .about-2-thumb .thumb-2 {
  margin-left: -90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-5-area .about-2-thumb .thumb-2 {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .about-5-area .about-2-thumb .thumb-2 {
    margin-left: 0px;
  }
}
.about-5-area .about-2-thumb .thumb-2 .box {
  right: 160px;
}
@media (max-width: 767px) {
  .about-5-area .about-2-thumb .thumb-2 .box {
    right: 0;
  }
}

.about-5-content {
  margin-left: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-5-content {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-5-content {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .about-5-content {
    margin-left: 0;
  }
}
.about-5-content .title {
  font-size: 60px;
  font-size: 70px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .about-5-content .title {
    font-size: 50px;
  }
}
.about-5-content .text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  color: #000;
}
.about-5-content .text span {
  font-weight: 400;
  color: #ff564f;
}
.about-5-content .nav {
  padding-top: 27px;
}
.about-5-content .nav li {
  margin-right: 10px;
}
.about-5-content .nav li a {
  height: 36px;
  line-height: 36px;
  background: linear-gradient(
    90deg,
    rgba(250, 208, 41, 0.2) 0%,
    rgba(255, 86, 79, 0.2) 65%
  );
  color: #ff564f;
  padding: 0 16px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .about-5-content .nav li a {
    margin-bottom: 10px;
  }
}
.about-5-content .nav li a.active {
  background: linear-gradient(90deg, #fad029 0%, #ff564f 65%);
  color: #fff;
}
.about-5-content .tab-content .tab-pane .text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}
.about-5-content .tab-content .tab-pane .list .item-1 {
  margin-right: 80px;
}
@media (max-width: 767px) {
  .about-5-content .tab-content .tab-pane .list .item-1 {
    margin: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-5-content .tab-content .tab-pane .list .item-1 {
    margin-right: 40px;
  }
}
.about-5-content .tab-content .tab-pane .list ul {
  margin: 0;
  padding-left: 16px;
}
.about-5-content .tab-content .tab-pane .list ul li a {
  line-height: 35px;
  color: #383838;
}

.about-6-area {
  background: #564fff;
  padding: 100px 0 150px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .about-6-area {
    padding: 100px 0 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-6-area {
    padding: 100px 0 150px;
  }
}
.about-6-area.about-12-area {
  background: linear-gradient(90deg, #13bf9e 0%, #108971 100%);
}

.about-6-content span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}
.about-6-content .title {
  font-size: 54px;
  line-height: 62px;
  color: #fff;
  padding-top: 45px;
  margin-bottom: 25px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .about-6-content .title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-6-content .title {
    font-size: 54px;
    line-height: 62px;
  }
}
.about-6-content .text {
  color: #fff;
  padding-right: 50px;
}
@media (max-width: 767px) {
  .about-6-content .text {
    padding-right: 0;
  }
}
.about-6-content ul {
  margin: 0;
  padding-left: 16px;
  padding-top: 10px;
}
.about-6-content ul li {
  color: #fff;
  line-height: 35px;
}
.about-6-content a {
  color: #fff;
  background: #ff564f;
  border-color: #ff564f;
  border-radius: 0;
  line-height: 56px;
  padding: 0;
  width: 156px;
  display: inline-block;
  margin-top: 44px;
}
.about-6-content a:hover {
  background-color: transparent;
  color: #ff564f;
}
.about-6-content.about-12-content a {
  background: #fad029;
  border-color: #fad029;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 18px;
  line-height: 62px;
  padding: 0 36px;
  width: auto;
  border-radius: 5px;
}
.about-6-content.about-12-content a:hover {
  background-color: transparent;
  border-color: #fad029;
  color: #fad029;
}

.about-6-thumb {
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-6-thumb {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .about-6-thumb {
    margin-top: 40px;
  }
}
.about-6-thumb::before {
  position: absolute;
  content: "";
  right: -95px;
  bottom: -50px;
  width: 350px;
  height: 600px;
  background: #000;
  z-index: -1;
}
@media (max-width: 767px) {
  .about-6-thumb::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-6-thumb::before {
    display: block;
  }
}
.about-6-thumb.about-12-thumb img {
  border-radius: 10px;
}
.about-6-thumb.about-12-thumb::before {
  border-radius: 10px;
}

.about-8-thumb .thumb-2 .box {
  background: #ff564f;
  border-radius: 0;
}

.about-8-content .nav li a {
  background: rgba(255, 86, 79, 0.2);
  border-radius: 0;
}
.about-8-content .nav li a.active {
  background: #ff564f;
}

.about-9-area {
  background: linear-gradient(312deg, #45108a 0%, #10054d 100%);
  padding-top: 100px;
  padding-bottom: 100px;
}

.about-9-thumb {
  margin-left: -85px;
  margin-right: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-9-thumb {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .about-9-thumb {
    margin-left: 0;
    margin-right: 0;
  }
}
.about-9-thumb img {
  width: 100%;
}

.about-9-content {
  color: #fff;
}
.about-9-content .title {
  color: #fff;
  font-size: 54px;
  line-height: 62px;
  margin-bottom: 23px;
}
@media (max-width: 767px) {
  .about-9-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-9-content .title {
    font-size: 54px;
    line-height: 64px;
  }
}
.about-9-content ul {
  margin: 0;
  padding-left: 16px;
  padding-top: 10px;
}
.about-9-content ul li {
  line-height: 35px;
}

.brand-12-area .brand-item img {
  display: inline-block;
  opacity: 0.3;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  cursor: pointer;
}
.brand-12-area .brand-item:hover img {
  opacity: 1;
}

/*===========================
    5.SERVICE css 
===========================*/
.service-area {
  background: #f3f3f3;
  padding-top: 86px;
}
.service-area.service-8-area {
  background: #fff;
}
.service-area.service-page-area {
  padding-top: 70px;
}
.service-area.pricing-service-1 {
  padding-top: 84px;
}
.service-area.service-15-area {
  padding-bottom: 100px;
}

.service-item {
  background: #fff;
  border-radius: 5px;
  padding: 50px 30px 30px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.service-item .title {
  font-size: 25px;
  font-family: "space_grotesk";
  margin-top: 37px;
  margin-bottom: 18px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  font-weight: 600;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .service-item .title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-item .title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .service-item .title {
    font-size: 20px;
  }
}
.service-item p {
  font-family: "space_grotesk";
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.service-item svg rect.white,
.service-item svg path.white {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.service-item:hover {
  background: #2aa38c;
  box-shadow: 0px 15px 60px 0 rgba(35, 92, 81, 0.25);
}
.service-item:hover .title {
  color: #fff;
}
.service-item:hover p {
  color: #fff;
}
.service-item:hover svg rect.white,
.service-item:hover svg path.white {
  fill: #fff;
}
.service-item.service-8-item {
  box-shadow: 0px 20px 90px rgba(0, 0, 0, 0.08);
}
.service-item.service-8-item:hover {
  background: #ff564f;
}
.service-item.service-8-item:hover svg path {
  fill: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-item.item-3 {
    display: none;
  }
}
.service-item.service-15-item:hover {
  background: #f0323c;
}

.service-2-area {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 10;
}
.service-2-area .section__bg {
  background-image: url(../images/service-bg.jpg);
}

.service-2-item {
  background: #fff;
  border-radius: 5px;
  padding: 40px 30px;
  font-family: "space_grotesk";
  color: #000;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.service-2-item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(330deg, #ff564f 0%, #fad029 80%);
  z-index: -3;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.service-2-item .icon {
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  background: linear-gradient(
    rgba(255, 86, 79, 0.15) 0%,
    rgba(250, 208, 41, 0.15) 100%
  );
  border-radius: 50%;
  display: inline-block;
  position: relative;
  z-index: 10;
}
.service-2-item .icon::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  z-index: -2;
}
.service-2-item .title {
  font-size: 25px;
  font-weight: 600;
  margin-top: 35px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .service-2-item .title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .service-2-item .title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-2-item .title {
    font-size: 18px;
  }
}
.service-2-item p {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
@media (max-width: 767px) {
  .service-2-item p {
    font-size: 16px;
  }
}
.service-2-item:hover::before {
  opacity: 1;
}
.service-2-item:hover .icon::before {
  transform: translate(-50%, -50%) scale(1);
}
.service-2-item:hover .title {
  color: #fff;
}
.service-2-item:hover p {
  color: #fff;
}
.service-2-item:hover .service-dot img {
  opacity: 0.5;
}
.service-2-item.service-11-item::before {
  background: #564fff;
}
.service-2-item.service-11-item .icon {
  background: rgba(74, 122, 255, 0.08);
}
.service-2-item .service-dot {
  position: absolute;
  left: 0;
  bottom: -20px;
  height: 100%;
  width: 100%;
  z-index: -2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.service-2-item .service-dot > img {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.service-2-item .service-dot .item {
  position: absolute;
  right: 0;
  bottom: 20px;
  z-index: 10;
  height: 100px;
  width: 100px;
}
.service-2-item .service-dot .item img {
  opacity: 1;
  width: 100%;
}

.service-11-active .slick-arrow {
  position: absolute;
  top: -100px;
  height: 60px;
  width: 60px;
  border-radius: 5px;
  background: #e0e0e0;
  text-align: center;
  line-height: 60px;
  color: #000;
  z-index: 99;
  font-size: 36px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.service-11-active .slick-arrow:hover {
  background: #564fff;
  color: #fff;
}
.service-11-active .slick-arrow.prev {
  right: 100px;
}
.service-11-active .slick-arrow.next {
  right: 15px;
}

.our-services-area {
  background: #564fff;
  padding-top: 85px;
  padding-bottom: 100px;
}
.our-services-area.our-services-4-area {
  background: linear-gradient(90deg, #6249ef 0%, #2895dd 100%);
}

.our-services-title {
  padding-bottom: 22px;
}
.our-services-title .title {
  color: #fff;
  font-size: 50px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  margin-bottom: 21px;
}
@media (max-width: 767px) {
  .our-services-title .title {
    font-size: 36px;
  }
}
.our-services-title span {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-family: "space_grotesk";
  display: inline-block;
  padding: 0 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .our-services-title span {
    padding: 0 0;
  }
}
@media (max-width: 767px) {
  .our-services-title span {
    padding: 0 0;
  }
}

.our-services-item {
  background: #fff;
  text-align: center;
  padding: 40px 0 15px;
}
.our-services-item .icon {
  background: rgba(86, 79, 255, 0.1);
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  z-index: 5;
}
.our-services-item .icon::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  height: 100%;
  width: 100%;
  background: #564fff;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.our-services-item .icon svg g path {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.our-services-item .title {
  font-family: "Playfair Display", serif;
  font-size: 22px;
  font-weight: 400;
  margin-top: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our-services-item .title {
    font-size: 18px;
  }
}
.our-services-item:hover .icon::before {
  transform: translate(-50%, -50%) scale(1);
}
.our-services-item:hover .icon svg g circle,
.our-services-item:hover .icon svg g line,
.our-services-item:hover .icon svg g rect,
.our-services-item:hover .icon svg g path {
  fill: #fff;
}
.our-services-item.our-services-4-item .icon::before {
  background: linear-gradient(90deg, #6249ef 0%, #2895dd 100%);
}
.our-services-item.our-services-item-6 {
  box-shadow: 0px 20px 90px rgba(0, 0, 0, 0.1);
}
.our-services-item.our-services-item-6 .title {
  font-family: "space_grotesk";
}
.our-services-item.our-services-item-9 .icon::before {
  background: linear-gradient(312deg, #45108a 0%, #10054d 100%);
}
.our-services-item.our-services-item-12 {
  box-shadow: 0px 20px 90px rgba(0, 0, 0, 0.1);
}
.our-services-item.our-services-item-12 .icon::before {
  background: #2aa38c;
}
.our-services-item.our-services-item-12 .title {
  font-family: "space_grotesk";
}

.services-5-area {
  padding-top: 90px;
  padding-bottom: 100px;
  background: #000;
}
.services-5-area .services-title .title {
  font-size: 54px;
  line-height: 62px;
  color: #fff;
}
@media (max-width: 767px) {
  .services-5-area .services-title .title {
    font-size: 40px;
  }
}

.about-service-3-area .services-5-item .sercices-content .title {
  font-family: "space_grotesk";
}

.services-5-item .sercices-content {
  overflow: hidden;
  position: relative;
  background: #e0e0e0;
  padding: 56px 50px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .services-5-item .sercices-content {
    padding: 56px 30px;
  }
}
@media (max-width: 767px) {
  .services-5-item .sercices-content {
    padding: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .services-5-item .sercices-content {
    padding: 56px 50px;
  }
}
.services-5-item .sercices-content .sercices-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 350px;
  width: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.services-5-item .sercices-content .sercices-overlay a {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  z-index: 5;
  border: 0;
  color: #fff;
  border-radius: 5px;
  line-height: 50px;
  overflow: hidden;
}
.services-5-item .sercices-content .sercices-overlay a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #fad029 0%, #ff564f 50%, #fad029 100%);
  background-size: 200% auto;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}
.services-5-item .sercices-content .sercices-overlay a:hover::before {
  background-position: right center;
}
.services-5-item .sercices-content .title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  color: #000;
}
.services-5-item .sercices-content ul {
  margin: 0;
  padding-left: 16px;
}
.services-5-item .sercices-content ul li {
  line-height: 35px;
  color: #383838;
}
.services-5-item .sercices-thumb img {
  width: 100%;
}
.services-5-item:hover .sercices-content .sercices-overlay {
  opacity: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services-5-item.item-3 {
    display: none;
  }
}

.services-6-area {
  padding-top: 90px;
  padding-bottom: 100px;
  background: #fff;
}

.service-item-6 {
  box-shadow: 0px 15px 30px 0 rgba(35, 92, 81, 0.25);
}
.service-item-6:hover {
  background: #564fff;
}

.service-7-area {
  background: #fff;
}

.service-7-item {
  border-radius: 6px;
  box-shadow: 0px 20px 90px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 50px 30px 42px;
}
.service-7-item a {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  position: relative;
  letter-spacing: 1px;
  margin-top: 6px;
}
.service-7-item a::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 100%;
  background: #fad029;
}
.service-7-item:hover a {
  color: #fff;
}

.service-9-area {
  background: #fff;
  padding-top: 90px;
}

.service-9-item {
  box-shadow: 0px 20px 90px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  text-align: center;
  color: #33415c;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.service-9-item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #45108a 0%, #10054d 100%);
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  z-index: -2;
}
.service-9-item .icon {
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  background: rgba(69, 16, 138, 0.1);
  display: inline-block;
}
.service-9-item .icon svg path {
  fill: #3d065f;
}
.service-9-item a {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  position: relative;
  display: inline-block;
  margin-top: 10px;
}
.service-9-item a::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0px;
  height: 2px;
  width: 100%;
  background: #000;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.service-9-item:hover {
  background: #fff;
  box-shadow: none;
}
.service-9-item:hover::before {
  opacity: 1;
}
.service-9-item:hover .icon {
  background: #fff;
}
.service-9-item:hover a {
  color: #fff;
}
.service-9-item:hover a::before {
  background: #fff;
}
.service-9-item .service-dot {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 150px;
  width: 100%;
  z-index: -1;
}
.service-9-item .service-dot img {
  width: 100%;
}

.services-10-area {
  background: #000;
  padding-top: 90px;
  padding-bottom: 100px;
}
.services-10-area .project-title .title {
  font-family: "Playfair Display", serif;
}

.our-services-item-10 .icon::before {
  background: #2aa38c;
}

.services-12-area {
  padding-top: 96px;
  padding-bottom: 100px;
}

/*===========================
    6.PORTFOLIO css 
===========================*/
.portfolio-area {
  padding-top: 86px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .portfolio-area .container-fluid {
    padding: 0 15px !important;
  }
}
.portfolio-area .container-fluid .col-lg-3 {
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .portfolio-area .container-fluid .col-lg-3 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.portfolio-area .section-title {
  padding-bottom: 22px;
}
.portfolio-area.portfolio-16-area {
  padding-top: 66px;
}
.portfolio-area.portfolio-16-area .portfolio-item .portfolio-overlay {
  background: linear-gradient(rgba(56, 99, 246, 0.5) 0%, #000 100%);
}
.portfolio-area.portfolio-16-area .portfolio-active .slick-arrow:hover {
  background: #3863f6;
}
.portfolio-area.portfolio-16-area .portfolio-active .slick-arrow:hover i {
  color: #fff;
}
.portfolio-area.portfolio-16-area
  .portfolio-active
  .slick-dots
  li.slick-active
  button {
  background: #3863f6;
}

.portfolio-item {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.portfolio-item .portfolio-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(255, 171, 74, 0.5) 0%, #000 100%);
  display: flex;
  align-items: flex-end;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.portfolio-item .portfolio-overlay .content {
  padding-left: 35px;
  padding-bottom: 5px;
  color: #fff;
  font-family: "space_grotesk";
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-item .portfolio-overlay .content {
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio-item .portfolio-overlay .content {
    padding-left: 15px;
  }
}
.portfolio-item .portfolio-overlay .content .title {
  font-size: 30px;
  color: #fff;
  font-family: "space_grotesk";
  padding-top: 21px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-item .portfolio-overlay .content .title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-item .portfolio-overlay .content .title {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .portfolio-item .portfolio-overlay .content .title {
    font-size: 24px;
  }
}
.portfolio-item > img {
  width: 100%;
}

.portfolio-active .slick-current .portfolio-item {
  transform: scale(1.05);
}
.portfolio-active .slick-current .portfolio-item .portfolio-overlay {
  opacity: 1;
}
.portfolio-active .slick-arrow {
  z-index: 9;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 130px;
  width: 130px;
  background: #fff;
  border-radius: 50%;
  line-height: 140px;
  font-size: 46px;
  color: #000;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}
.portfolio-active .slick-arrow i {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.portfolio-active .slick-arrow:hover {
  background: #ffab4a;
}
.portfolio-active .slick-arrow.prev {
  left: -3%;
  text-align: right;
  padding-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .portfolio-active .slick-arrow.prev {
    left: -4%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-active .slick-arrow.prev {
    left: -6%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-active .slick-arrow.prev {
    left: -7%;
  }
}
.portfolio-active .slick-arrow.next {
  right: -3%;
  text-align: left;
  padding-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .portfolio-active .slick-arrow.next {
    right: -4%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-active .slick-arrow.next {
    right: -6%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-active .slick-arrow.next {
    right: -7%;
  }
}
.portfolio-active .slick-dots {
  position: absolute;
  left: 50%;
  bottom: -47px;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.portfolio-active .slick-dots li {
  display: inline-block;
}
.portfolio-active .slick-dots li button {
  height: 6px;
  width: 6px;
  border: 0;
  font-size: 0px;
  border-radius: 50%;
  background: #e0e0e0;
  padding: 0;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.portfolio-active .slick-dots li.slick-active button {
  background: #ffab4a;
  width: 25px;
  border-radius: 10px;
}
.portfolio-active:hover .slick-arrow {
  opacity: 1;
}

.portfolio-2-area {
  padding-top: 100px;
  padding-bottom: 100px;
}

.portfolio-2-item {
  position: relative;
  overflow: hidden;
}
.portfolio-2-item img {
  width: 100%;
}
.portfolio-2-item .portfolio-overlay {
  position: absolute;
  left: 0;
  bottom: 20px;
  padding: 33px 45px 34px 25px;
  background: #fff;
  border-radius: 0 7px 7px 0;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-2-item .portfolio-overlay {
    padding: 33px 25px 34px 25px;
  }
}
@media (max-width: 767px) {
  .portfolio-2-item .portfolio-overlay {
    padding: 33px 15px 34px 15px;
  }
}
.portfolio-2-item .portfolio-overlay .title {
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: 600;
}
.portfolio-2-item .portfolio-overlay span {
  color: #ff564f;
  font-size: 14px;
}
.portfolio-2-item .portfolio-overlay-2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 320px;
  height: 200px;
  border-radius: 10px;
  background: linear-gradient(330deg, #ff564f 0%, #fad029 80%);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-2-item .portfolio-overlay-2 {
    width: 260px;
  }
}
@media (max-width: 767px) {
  .portfolio-2-item .portfolio-overlay-2 {
    width: 260px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-2-item .portfolio-overlay-2 {
    width: 320px;
  }
}
.portfolio-2-item .portfolio-overlay-2 .title {
  color: #fff;
  font-size: 25px;
  line-height: 30px;
  padding-top: 38px;
  margin-bottom: 4px;
}
.portfolio-2-item .portfolio-overlay-2 > span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  display: inline-block;
}
.portfolio-2-item .portfolio-overlay-2 a {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.portfolio-2-item .portfolio-overlay-2 a span {
  display: inline-block;
  margin-left: 3px;
  height: 24px;
  width: 24px;
  background: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 21px;
  transform: rotate(45deg);
}
.portfolio-2-item:hover .portfolio-overlay {
  left: -100%;
}
.portfolio-2-item:hover .portfolio-overlay-2 {
  transform: translate(-50%, -50%) scale(1);
}

.project-menu {
  text-align: center;
  padding: 26px 0 30px;
}
.project-menu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.project-menu ul li {
  display: inline-block;
  height: 56px;
  line-height: 60px;
  background: rgba(0, 0, 0, 0.05);
  color: #000;
  padding: 0 28px;
  border-radius: 5px;
  margin: 0 6px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .project-menu ul li {
    padding: 0 4px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-menu ul li {
    padding: 0 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-menu ul li {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .project-menu ul li {
    margin-bottom: 10px;
  }
}
.project-menu ul li.active {
  color: #fff;
  background: linear-gradient(90deg, #fad029 0%, #ff564f 65%);
}

.portfolio-style-2 {
  background: #f5f5f5;
  padding-top: 70px;
  padding-bottom: 100px;
}

.portfolio-style-2-item {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.portfolio-style-2-item > img {
  width: 100%;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.portfolio-style-2-item .project-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  padding-left: 35px;
  padding-top: 34px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-style-2-item .project-overlay {
    padding-left: 20px;
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio-style-2-item .project-overlay {
    padding-left: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-style-2-item .project-overlay {
    padding-left: 35px;
    padding-top: 34px;
  }
}
.portfolio-style-2-item .project-overlay .title {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  font-family: "space_grotesk";
  margin-top: 4px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-style-2-item .project-overlay .title {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
.portfolio-style-2-item .project-overlay span {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.portfolio-style-2-item .project-overlay a {
  position: absolute;
  left: 35px;
  bottom: 30px;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 54px;
  background: #ff564f;
  border-radius: 50%;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-style-2-item .project-overlay a {
    left: 20px;
  }
}
.portfolio-style-2-item:hover > img {
  transform: scale(1.1);
}
.portfolio-style-2-item:hover .project-overlay {
  opacity: 1;
}
@media (max-width: 767px) {
  .portfolio-style-2-item.item-8 {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-style-2-item.item-8 {
    display: block;
  }
}
.portfolio-style-2-item.portfolio-style-3-item .project-overlay {
  background: rgba(0, 0, 0, 0.6);
  padding: 0;
}
.portfolio-style-2-item.portfolio-style-3-item .project-overlay .item .title {
  font-family: "space_grotesk";
  font-weight: 700;
  margin-bottom: 8px;
}

.portfolio-style-3-area {
  padding-top: 67px;
  padding-bottom: 100px;
}
.portfolio-style-3-area.portfolio-service-3 {
  padding-bottom: 65px;
}

.portfolio-style-4-area {
  background: #f5f5f5;
  padding-top: 67px;
  padding-bottom: 100px;
}
.portfolio-style-4-area .section-title ul {
  display: inline-block;
  padding-top: 25px;
  padding-bottom: 20px;
}
.portfolio-style-4-area .section-title ul li {
  display: inline-block;
  line-height: 55px;
  background: #fff;
  color: #000;
  box-shadow: 5px;
  padding: 0 28px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin: 0 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-4-area .section-title ul li {
    margin: 0 5px 10px;
  }
}
.portfolio-style-4-area .section-title ul li.active {
  background: #ff564f;
  color: #fff;
}

.portfolio-style-4-item {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.portfolio-style-4-item .portfolio-thumb {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.portfolio-style-4-item .portfolio-thumb a {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  border-radius: 5px;
  background: #fff;
  line-height: 64px;
  padding: 0 25px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  letter-spacing: 1px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  white-space: nowrap;
}
.portfolio-style-4-item .portfolio-thumb a span {
  display: inline-block;
  background: #ff564f;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.portfolio-style-4-item .portfolio-thumb > img {
  width: 100%;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.portfolio-style-4-item .portfolio-content {
  padding: 21px 10px 23px 25px;
}
.portfolio-style-4-item .portfolio-content .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .portfolio-style-4-item .portfolio-content .title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-style-4-item .portfolio-content .title {
    font-size: 20px;
  }
}
.portfolio-style-4-item .portfolio-content span {
  font-size: 14px;
  color: #ff564f;
}
.portfolio-style-4-item:hover {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
}
.portfolio-style-4-item:hover .portfolio-thumb img {
  transform: scale(1.1);
}
.portfolio-style-4-item:hover .portfolio-thumb a {
  top: 50%;
  opacity: 1;
}

.portfolio-style-5-area {
  padding-top: 67px;
  padding-bottom: 100px;
}

.portfolio-style-5-item {
  position: relative;
  overflow: hidden;
}
.portfolio-style-5-item > img {
  width: 100%;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.portfolio-style-5-item .portfolio-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.portfolio-style-5-item .portfolio-overlay .item {
  padding-left: 25px;
  padding-bottom: 18px;
  position: relative;
  width: 100%;
}
.portfolio-style-5-item .portfolio-overlay .item .title {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .portfolio-style-5-item .portfolio-overlay .item .title br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-style-5-item .portfolio-overlay .item .title {
    font-size: 18px;
  }
}
.portfolio-style-5-item .portfolio-overlay .item span {
  color: #fff;
  font-size: 14px;
  display: block;
}
.portfolio-style-5-item .portfolio-overlay .item a {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
  background: #ff564f;
  display: inline-block;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-style-5-item .portfolio-overlay .item a {
    position: static;
    transform: translateY(0);
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio-style-5-item .portfolio-overlay .item a {
    position: static;
    transform: translateY(0);
    margin-top: 10px;
  }
}
.portfolio-style-5-item:hover .portfolio-overlay {
  opacity: 1;
}
.portfolio-style-5-item:hover > img {
  transform: scale(1.1);
}

.portfolio-details-area {
  padding-top: 60px;
  padding-bottom: 83px;
}

.portfolio-details-top .portfolio-details-top-title .title {
  font-size: 54px;
  line-height: 62px;
  font-weight: 500;
  padding-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-details-top .portfolio-details-top-title .title {
    font-size: 46px;
    line-height: 54px;
  }
}
@media (max-width: 767px) {
  .portfolio-details-top .portfolio-details-top-title .title {
    font-size: 26px;
    line-height: 40px;
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-details-top .portfolio-details-top-title .title {
    font-size: 46px;
    line-height: 56px;
    padding-right: 0;
  }
}
.portfolio-details-top .portfolio-details-top-text {
  margin-left: -10px;
}

.portfolio-details-category .portfolio-details-category-thumb img {
  width: 100%;
}
.portfolio-details-category .portfolio-details-category-list {
  margin-left: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-category .portfolio-details-category-list {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .portfolio-details-category .portfolio-details-category-list {
    margin-left: 0;
  }
}
.portfolio-details-category .portfolio-details-category-list .item {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 32px 45px 16px 30px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-details-category .portfolio-details-category-list .item {
    padding: 14px 15px 10px 30px;
  }
}
@media (max-width: 767px) {
  .portfolio-details-category .portfolio-details-category-list .item {
    padding: 14px 15px 10px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-details-category .portfolio-details-category-list .item {
    padding: 32px 45px 16px 30px;
  }
}
.portfolio-details-category .portfolio-details-category-list .item span {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #383838;
  padding-left: 20px;
  position: relative;
  letter-spacing: 1px;
}
.portfolio-details-category
  .portfolio-details-category-list
  .item
  span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 8px;
  width: 8px;
  background: #ffab4a;
}
.portfolio-details-category .portfolio-details-category-list .item .title {
  font-size: 20px;
  font-weight: 400;
  margin-top: 2px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-details-category .portfolio-details-category-list .item .title {
    font-size: 18px;
  }
}

.portfolio-details-content .text {
  color: #000;
}
.portfolio-details-content .text ul {
  margin: 0;
  padding-left: 16px;
}
.portfolio-details-content .text ul li {
  color: #000;
  line-height: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-details-content .text ul li {
    font-size: 16px;
  }
}
.portfolio-details-content .text ul li:nth-child(2) {
  text-decoration: underline;
}

.portfolio-business-planing .title {
  font-size: 40px;
  font-weight: 500;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-business-planing .business-planing-items {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .portfolio-business-planing .business-planing-items {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-business-planing .business-planing-items .item {
    margin: 65px 40px 0;
  }
}
@media (max-width: 767px) {
  .portfolio-business-planing .business-planing-items .item {
    margin: 65px 20px 0;
  }
}
.portfolio-business-planing .business-planing-items .item span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  display: block;
  padding-top: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-thumb .portfolio-details-thumb-1 {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .portfolio-details-thumb .portfolio-details-thumb-1 {
    margin-bottom: 40px;
  }
}
.portfolio-details-thumb .portfolio-details-thumb-1 img {
  width: 100%;
}
.portfolio-details-thumb .portfolio-details-thumb-2 {
  position: relative;
}
.portfolio-details-thumb .portfolio-details-thumb-2 img {
  width: 100%;
}
.portfolio-details-thumb .portfolio-details-thumb-2 a {
  position: absolute;
  top: 50%;
  right: -42px;
  transform: translateY(-50%);
  height: 85px;
  width: 85px;
  background: #ffab4a;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  font-size: 20px;
  color: #383838;
  border: 8px solid #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-details-thumb .portfolio-details-thumb-2 a {
    right: -30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-thumb .portfolio-details-thumb-2 a {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  .portfolio-details-thumb .portfolio-details-thumb-2 a {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

.portfolio-details-social .quote {
  padding-left: 36px;
  position: relative;
  padding-right: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-details-social .quote {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-social .quote {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .portfolio-details-social .quote {
    margin-bottom: 30px;
    padding-right: 0;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio-details-social .quote {
    padding-right: 80px;
    padding-left: 20px;
  }
}
.portfolio-details-social .quote::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background: #ffab4a;
}
.portfolio-details-social .quote .text {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.portfolio-details-social .quote .info {
  padding-left: 65px;
  position: relative;
}
.portfolio-details-social .quote .info img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.portfolio-details-social .quote .info .title {
  font-size: 16px;
  font-weight: 600;
  color: #383838;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.portfolio-details-social .quote .info span {
  font-size: 14px;
  color: #000;
  display: block;
  margin-top: -3px;
}
.portfolio-details-social ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.portfolio-details-social ul li a {
  background: #1da1f2;
  border-radius: 5px;
  line-height: 44px;
  display: inline-block;
  padding: 0;
  margin-bottom: 8px;
  margin-left: 8px;
  white-space: nowrap;
  width: 170px;
  text-align: center;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .portfolio-details-social ul li a {
    width: 135px;
  }
}
@media (max-width: 767px) {
  .portfolio-details-social ul li a {
    width: 130px;
  }
}
.portfolio-details-social ul li a i {
  padding-right: 6px;
}
.portfolio-details-social ul li:nth-child(2) a {
  background: #f56040;
}
.portfolio-details-social ul.item-2 li a {
  background: #3b5998;
}
.portfolio-details-social ul.item-2 li:nth-child(2) a {
  background: #ff3838;
}

.client-area {
  position: relative;
  z-index: 10;
  padding-top: 100px;
  padding-bottom: 100px;
}
.client-area .section__bg {
  background-image: url(../images/client-bg.jpg);
}

.client-slide .item {
  padding: 50px;
  background: #fff;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .client-slide .item {
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .client-slide .item {
    padding: 40px;
  }
}
.client-slide .item .content {
  font-size: 18px;
  font-weight: 500;
  position: relative;
  padding-left: 28px;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .client-slide .item .content {
    padding-left: 20px;
  }
}
.client-slide .item .content::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background: #e0e0e0;
}
.client-slide .item .content .info {
  padding-left: 65px;
  position: relative;
}
.client-slide .item .content .info img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.client-slide .item .content .info .title {
  font-size: 16px;
  font-weight: 600;
  color: #383838;
  margin-bottom: 0;
}
.client-slide .item .content .info span {
  font-size: 14px;
  color: #000;
  display: block;
  margin-top: -3px;
}
.client-slide .slick-dots {
  position: absolute;
  right: 50px;
  top: 60px;
}
.client-slide .slick-dots li {
  display: inline-block;
}
.client-slide .slick-dots li button {
  border: 0;
  background: #e0e0e0;
  border-radius: 50%;
  font-size: 0;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  padding: 0;
}
.client-slide .slick-dots li.slick-active button {
  border-radius: 10px;
  width: 30px;
  background: #ffab4a;
}

/*===========================
    7.TESTIMONIAL css 
===========================*/
.testimonial-area {
  background: #000;
  padding-top: 88px;
  padding-bottom: 143px;
}
.testimonial-area.testimonial-5-area {
  padding-top: 68px;
}
.testimonial-area.testimonial-5-area .section-title {
  padding-bottom: 0;
}
.testimonial-area.testimonial-5-area .section-title .title {
  margin-bottom: 10px;
}
.testimonial-area.about-testimonial-area {
  background: #fff;
}
.testimonial-area.about-testimonial-area .section-title .title {
  color: #000;
}
.testimonial-area.testimonial-16-area
  .testimonial-active
  .slick-current
  .testimonial-item
  .quote
  svg
  path {
  fill: #3863f6;
}
.testimonial-area.testimonial-16-area
  .testimonial-active
  .slick-dots
  li.slick-active
  button {
  background: #3863f6;
}

.testimonial-item {
  background: #383838;
  border-radius: 5px;
  padding: 35px 30px 40px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.testimonial-item .quote {
  padding-bottom: 20px;
}
.testimonial-item .quote svg path {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.testimonial-item .info {
  position: relative;
  padding-left: 70px;
  margin-top: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .testimonial-item .info {
    padding-left: 65px;
  }
}
.testimonial-item .info img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.testimonial-item .info .title {
  margin: 0 0 3px;
  color: #fff;
  font-size: 20px;
  font-family: "space_grotesk";
}
.testimonial-item .info span {
  color: #e0e0e0;
}
@media (max-width: 767px) {
  .testimonial-item .info span {
    font-size: 14px;
  }
}

.testimonial-active .slick-current .testimonial-item {
  background: #235c51;
}
.testimonial-active .slick-current .testimonial-item .quote svg path {
  fill: #ffab4a;
}
.testimonial-active .slick-dots {
  position: absolute;
  left: 50%;
  bottom: -62px;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.testimonial-active .slick-dots li {
  display: inline-block;
}
.testimonial-active .slick-dots li button {
  height: 6px;
  width: 6px;
  border: 0;
  font-size: 0px;
  border-radius: 50%;
  background: #33415c;
  padding: 0;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.testimonial-active .slick-dots li.slick-active button {
  background: #ffab4a;
  width: 25px;
  border-radius: 10px;
}
.testimonial-active .slick-current .testimonial-5-item {
  background: #f5f5f5;
  color: #383838;
}
.testimonial-active .slick-current .testimonial-5-item .info .title {
  color: #383838;
}
.testimonial-active .slick-current .testimonial-5-item .info span {
  color: #383838;
}

.testimonial-2-area {
  background: #fff;
  padding-top: 100px;
  padding-bottom: 93px;
  position: relative;
}
.testimonial-2-area::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 45%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/testimonial-bg.jpg);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-2-area::before {
    width: 0;
  }
}
@media (max-width: 767px) {
  .testimonial-2-area::before {
    width: 0;
  }
}
.testimonial-2-area .section-title-3 .title {
  padding-right: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .testimonial-2-area .section-title-3 .title {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .testimonial-2-area .section-title-3 .title {
    padding-right: 0;
  }
}
.testimonial-2-area.testimonial-15-area .section-title-3 span {
  background: #f0323c;
  color: #fff;
}
.testimonial-2-area.testimonial-15-area
  .testimonial-2-active
  .slick-dots
  li.slick-active
  button {
  background: #f0323c;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .testimonial-2-active {
    margin-top: 40px;
  }
}
.testimonial-2-active .item {
  position: relative;
  padding-left: 32px;
  color: #383838;
}
.testimonial-2-active .item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 210px;
  background: #f5f5f5;
}
.testimonial-2-active .item > span {
  font-size: 18px;
  font-family: "space_grotesk";
  color: #383838;
  display: inline-block;
  padding-bottom: 14px;
}
.testimonial-2-active .item .info {
  position: relative;
  padding-left: 70px;
}
.testimonial-2-active .item .info img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.testimonial-2-active .item .info h5 {
  margin-bottom: 5px;
  font-size: 20px;
  font-family: "space_grotesk";
}
.testimonial-2-active .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .testimonial-2-active .slick-dots {
    bottom: -60px;
  }
}
.testimonial-2-active .slick-dots li {
  display: inline-block;
}
.testimonial-2-active .slick-dots li button {
  border: 0;
  font-size: 0;
  background: #e0e0e0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 0 5px;
  padding: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.testimonial-2-active .slick-dots li.slick-active button {
  width: 25px;
  border-radius: 10px;
  background: #ffab4a;
}

.testimonial-3-area {
  background: #000;
  padding-top: 80px;
  padding-bottom: 80px;
}
.testimonial-3-area.testimonial-14-area
  .testimonial-3-active
  .testimonial-items
  .testimonial-3-content
  .title {
  font-family: "space_grotesk";
}

.testimonial-3-content {
  padding-right: 50px;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .testimonial-3-content {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-3-content {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .testimonial-3-content {
    padding-right: 0;
  }
}
.testimonial-3-content > .title {
  font-size: 40px;
  line-height: 48px;
  color: #fff;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  margin-bottom: 62px;
}
@media (max-width: 767px) {
  .testimonial-3-content > .title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-3-content > .title {
    font-size: 40px;
    line-height: 48px;
  }
}
.testimonial-3-content img {
  margin-bottom: 28px;
}
.testimonial-3-content .info {
  padding-top: 30px;
}
.testimonial-3-content .info .title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  font-family: "Playfair Display", serif;
  margin-bottom: 5px;
}
.testimonial-3-content .info span {
  font-size: 14px;
  color: #fff;
  opacity: 0.75;
}

.testimonial-3-thumb {
  position: relative;
  z-index: 5;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-3-thumb {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .testimonial-3-thumb {
    margin-top: 100px;
  }
}
.testimonial-3-thumb::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 740px;
  width: 318px;
  background: #564fff;
  z-index: -1;
}

.testimonial-3-active .testimonial-items {
  margin-top: 70px;
  padding-bottom: 70px;
}
.testimonial-3-active .slick-dots {
  position: absolute;
  right: 125px;
  bottom: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.testimonial-3-active .slick-dots li {
  display: inline-block;
}
.testimonial-3-active .slick-dots li button {
  border: 0;
  border-radius: 50%;
  background: #fff;
  opacity: 0.3;
  height: 10px;
  width: 10px;
  font-size: 0;
  display: inline-block;
  padding: 0;
  margin-left: 10px;
}
.testimonial-3-active .slick-dots li.slick-active button {
  border-radius: 10px;
  width: 30px;
  background: #ff564f;
  opacity: 1;
}
.testimonial-3-active.testimonial-12-active
  .testimonial-items
  .testimonial-3-thumb::before {
  background: #2aa38c;
  border-radius: 10px;
}
.testimonial-3-active.testimonial-12-active
  .testimonial-items
  .testimonial-3-thumb
  img {
  border-radius: 10px;
}
.testimonial-3-active.testimonial-12-active .slick-dots li.slick-active button {
  background: #fff;
}
.testimonial-3-active.testimonial-12-active .testimonial-3-content > .title {
  font-family: "space_grotesk";
}

.testimonial-6-area {
  background: #000;
  padding-top: 90px;
  padding-bottom: 145px;
  position: relative;
  z-index: 10;
}
.testimonial-6-area .testimonial-shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.testimonial-6-area .testimonial-shape .item-1 {
  position: absolute;
  right: 0;
  top: 0;
}
.testimonial-6-area .testimonial-shape .item-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.testimonial-6-active .slick-dots li.slick-active button {
  background: #ff564f;
}
.testimonial-6-active .slick-current .testimonial-item {
  background: #564fff;
  border-radius: 0;
}
.testimonial-6-active .slick-current .testimonial-item .quote svg path {
  fill: #fff;
}

.testimonial-8-active .slick-dots li.slick-active button {
  background: #ff564f;
}
.testimonial-8-active .slick-current .testimonial-item {
  background: #fff;
  border-radius: 0;
  color: #000;
}
.testimonial-8-active .slick-current .testimonial-item .quote svg path {
  fill: #ff564f;
}
.testimonial-8-active .slick-current .testimonial-item .info .title {
  color: #000;
}
.testimonial-8-active .slick-current .testimonial-item .info span {
  color: #000;
}

.testimonial-9-active .slick-dots {
  opacity: 0;
  visibility: hidden;
}
.testimonial-9-active .testimonial-9-thumb {
  padding-left: 100px;
}
@media (max-width: 767px) {
  .testimonial-9-active .testimonial-9-thumb {
    padding-left: 0;
    margin-top: 40px;
  }
}
.testimonial-9-active .testimonial-9-thumb::before {
  right: auto;
  left: 0;
  background: linear-gradient(90deg, #45108a 0%, #10054d 100%);
}
@media (max-width: 767px) {
  .testimonial-9-active .testimonial-9-thumb::before {
    display: none;
  }
}
.testimonial-9-active .testimonial-11-thumb::before {
  background: #564fff;
  border-radius: 10px;
}
.testimonial-9-active .testimonial-11-thumb img {
  border-radius: 10px;
}
.testimonial-9-active .testimonial-3-content > .title {
  font-family: "space_grotesk";
}

.testimonial-10-area {
  background: #000;
  padding-top: 90px;
  padding-bottom: 145px;
}
.testimonial-10-area .project-title .title {
  font-family: "Playfair Display", serif;
}

.testimonial-active.testimonial-10-active .slick-dots li.slick-active button {
  background: #2aa38c;
}
.testimonial-active.testimonial-10-active
  .slick-current
  .testimonial-item
  .quote
  svg
  path {
  fill: #fff;
}

/*===========================
    8.OVERVIEW css 
===========================*/
.overview-area {
  background: #f3f3f3;
  padding-top: 88px;
}
.overview-area.overview-7-area {
  background: #fff;
  padding-top: 28px;
}

.overview-content {
  padding-right: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-content {
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .overview-content {
    padding-right: 0px;
  }
}
.overview-content span {
  display: block;
  font-size: 25px;
  font-family: "space_grotesk";
  text-transform: uppercase;
  color: #000;
  padding-top: 5px;
  padding-bottom: 38px;
  font-weight: 500;
}
.overview-content .title {
  font-size: 54px;
  line-height: 62px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .overview-content .title {
    font-size: 32px;
    line-height: 42px;
  }
}
.overview-content > a {
  margin-top: 35px;
  font-size: 18px;
}
.overview-content .brand-item .item {
  margin-right: 45px;
}
.overview-content .brand-item .item:last-child {
  margin-right: 0;
}
.overview-content .brand-item .item a {
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.overview-content .brand-item .item a:hover {
  opacity: 1;
}

.overview-counter-area {
  margin-left: -30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-counter-area {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .overview-counter-area {
    margin-left: 0;
  }
}

.overview-counter-item {
  background: #fff;
  padding: 40px 40px 30px;
  border-radius: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-counter-item {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .overview-counter-item {
    margin-top: 30px;
    text-align: center;
  }
}
.overview-counter-item .icon {
  height: 77px;
}
.overview-counter-item .title {
  font-size: 60px;
  font-weight: 500;
  padding-top: 30px;
  margin-bottom: 6px;
}
.overview-counter-item > span {
  display: inline-block;
  font-size: 20px;
}
@media (max-width: 767px) {
  .overview-counter-item > span {
    font-size: 16px;
  }
}

/*===========================
    9.TEAM css 
===========================*/
.team-area {
  padding-top: 88px;
  padding-bottom: 38px;
  overflow: hidden;
}
.team-area .container-fluid .col-lg-3 {
  padding-left: 5px;
  padding-right: 5px;
}
.team-area .section-title {
  padding-bottom: 6px;
}

.team-item {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  z-index: 4;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team-item .team-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(255, 171, 74, 0.5) 0%, #000 100%);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team-item .team-overlay .content {
  padding-left: 20px;
  padding-bottom: 10px;
}
.team-item .team-overlay .content .title {
  color: #fff;
  font-size: 25px;
  margin-bottom: 1px;
}
.team-item .team-overlay .content span {
  color: #e0e0e0;
  font-family: "space_grotesk";
  display: inline-block;
  padding-bottom: 6px;
}
.team-item .team-overlay .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.team-item .team-overlay .content ul li {
  display: inline-block;
  margin-right: 16px;
}
.team-item .team-overlay .content ul li a {
  color: #fff;
  font-size: 13px;
}
.team-item .team-overlay .content ul li:last-child {
  margin-right: 0;
}
.team-item img {
  width: 100%;
}

.team-active .slick-current .team-item {
  box-shadow: 0px 25px 60px rgba(0, 0, 0, 0.6);
  z-index: 5;
  transform: scale(1.05);
}
.team-active .slick-current .team-item .team-overlay {
  opacity: 1;
}
.team-active .slick-arrow {
  z-index: 9;
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  height: 130px;
  width: 130px;
  background: #fff;
  border-radius: 50%;
  line-height: 140px;
  font-size: 46px;
  color: #000;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}
.team-active .slick-arrow:hover {
  background: #ffab4a;
}
.team-active .slick-arrow.prev {
  left: -3%;
  text-align: right;
  padding-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .team-active .slick-arrow.prev {
    left: -5%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .team-active .slick-arrow.prev {
    left: -6%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-active .slick-arrow.prev {
    left: -10%;
  }
}
.team-active .slick-arrow.next {
  right: -3%;
  text-align: left;
  padding-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .team-active .slick-arrow.next {
    right: -5%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .team-active .slick-arrow.next {
    right: -6%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-active .slick-arrow.next {
    right: -10%;
  }
}
.team-active .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 42px;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 9;
}
.team-active .slick-dots li {
  display: inline-block;
}
.team-active .slick-dots li button {
  height: 6px;
  width: 6px;
  border: 0;
  font-size: 0px;
  border-radius: 50%;
  background: #e0e0e0;
  padding: 0;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.team-active .slick-dots li.slick-active button {
  background: #ffab4a;
  width: 25px;
  border-radius: 10px;
}
.team-active:hover .slick-arrow {
  opacity: 1;
}

.team-2-area {
  padding-top: 100px;
  padding-bottom: 100px;
}
.team-2-area.team-servies-3 {
  padding-top: 0;
  padding-bottom: 68px;
}
.team-2-area.team-8-area {
  padding-top: 83px;
}
.team-2-area.team-service-area {
  padding-top: 65px;
}
.team-2-area.team-2-about {
  padding-top: 66px;
  padding-bottom: 100px;
}
.team-2-area.team-2-about .team-2-item .team-overlay-2 {
  background: #fff;
}
.team-2-area.team-about-3 {
  padding-top: 66px;
}

.team-2-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 10;
}
.team-2-item > img {
  width: 100%;
}
.team-2-item .team-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(250, 208, 41, 0.25) 0%, #ff564f 100%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.team-2-item .team-overlay .item {
  text-align: center;
  padding-bottom: 10px;
}
.team-2-item .team-overlay .item .title {
  color: #fff;
  font-size: 22px;
  font-family: "space_grotesk";
  margin-bottom: 0px;
}
.team-2-item .team-overlay .item span {
  color: #fff;
  font-size: 15px;
  font-family: "space_grotesk";
}
.team-2-item .team-overlay .item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.team-2-item .team-overlay .item ul li {
  display: inline-block;
}
.team-2-item .team-overlay .item ul li a {
  color: #fff;
  margin: 0 3px;
}
.team-2-item .team-overlay-2 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 2;
  padding-top: 45px;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .team-2-item .team-overlay-2 {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-2-item .team-overlay-2 {
    padding-top: 20px;
  }
}
@media (max-width: 767px) {
  .team-2-item .team-overlay-2 {
    padding-top: 60px;
  }
}
.team-2-item .team-overlay-2 .title {
  font-size: 22px;
  font-family: "space_grotesk";
  padding-top: 33px;
  margin-bottom: 5px;
}
.team-2-item:hover .team-overlay-2 {
  opacity: 0;
  z-index: -1;
}
.team-2-item.team-12-item .team-overlay {
  background: linear-gradient(rgba(250, 208, 41, 0.25) 0%, #564fff 100%);
}
.team-2-item.team-service-item .team-overlay-2 {
  background: #f5f5f5;
}

.team-5-area {
  background: #fff;
  padding-bottom: 83px;
}
.team-5-area .team-title .title {
  font-size: 54px;
  line-height: 62px;
}
.team-5-area .team-5-item {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
  margin-bottom: 80px;
}
.team-5-area .team-5-item img {
  display: inline-block;
}
.team-5-area.team-16-area .team-2-item .team-overlay {
  background: linear-gradient(rgba(250, 208, 41, 0.25) 0%, #3863f6 100%);
}
.team-5-area.team-16-area .team-5-active .slick-dots li.slick-active button {
  background: #3863f6;
}

.team-5-active .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style: none;
}
.team-5-active .slick-dots li {
  display: inline-block;
}
.team-5-active .slick-dots li button {
  height: 6px;
  width: 6px;
  border: 0;
  background: #e0e0e0;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  padding: 0;
  margin: 0 5px;
}
.team-5-active .slick-dots li.slick-active button {
  width: 25px;
  border-radius: 10px;
  background: linear-gradient(90deg, #fad029 0%, #ff564f 65%);
}

/*===========================
    10.PRICING css 
===========================*/
.pricing-area {
  background: #000;
  padding-top: 88px;
  padding-bottom: 100px;
}
.pricing-area.pricing-page-1 {
  padding-top: 70px;
}

.pricing-item {
  background: #fff;
  padding: 44px 40px 40px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  color: #383838;
}
.pricing-item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 162px;
  background: #235c51;
  border-radius: 10px;
  z-index: -1;
}
.pricing-item .title {
  color: #fff;
  margin-bottom: 4px;
  font-size: 40px;
  line-height: 50px;
}
.pricing-item span {
  display: inline-block;
  font-size: 18px;
  font-family: "space_grotesk";
  color: #ffab4a;
  padding-bottom: 64px;
}
.pricing-item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 6px;
}
.pricing-item ul li {
  font-size: 18px;
  font-family: "space_grotesk";
  color: #000;
  line-height: 38px;
  padding-left: 20px;
  position: relative;
  margin-bottom: 9px;
}
.pricing-item ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #2aa38c;
}
.pricing-item a {
  width: 100%;
  border: 1px solid #2aa38c;
  background-color: transparent;
  color: #2aa38c;
  text-transform: capitalize;
  border-radius: 5px;
  margin-top: 30px;
}
.pricing-item a:hover {
  background: #ffab4a;
  border-color: #ffab4a;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-item.center {
    display: none;
  }
}
.pricing-item.center::before {
  background: #ffab4a;
}
.pricing-item.center .title {
  color: #000;
}
.pricing-item.center span {
  color: #383838;
}
.pricing-item.center a {
  background: #ffab4a;
  border-color: #ffab4a;
  color: #000;
}
.pricing-item.center a:hover {
  background-color: transparent;
  color: #2aa38c;
  border-color: #2aa38c;
}
.pricing-item.center .tag {
  background: #383838;
  line-height: 30px;
  width: 165px;
  text-align: center;
  color: #fff;
  position: absolute;
  right: -45px;
  top: 20px;
  transform: rotate(45deg);
}

.pricing-2-area {
  background: #000;
  padding-top: 100px;
  padding-bottom: 108px;
}
.pricing-2-area.pricing-page-area {
  padding-top: 70px;
}
.pricing-2-area.pricing-page-area .section-title {
  color: #fff;
}
.pricing-2-area.pricing-page-area .section-title .title {
  color: #fff;
}

.pricing-2-item {
  position: relative;
  z-index: 10;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .pricing-2-item {
    margin-right: 0;
  }
}
.pricing-2-item::before {
  content: "";
  position: absolute;
  right: -8px;
  bottom: -8px;
  height: 100%;
  width: 100%;
  background: linear-gradient(330deg, #ff564f 0%, #fad029 80%);
  border-radius: 5px;
  z-index: -1;
}
.pricing-2-item .item {
  background: #fff;
  padding: 57px 50px;
  border-radius: 5px;
  position: relative;
  z-index: 5;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .pricing-2-item .item {
    padding: 57px 30px;
  }
}
@media (max-width: 767px) {
  .pricing-2-item .item {
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pricing-2-item .item {
    padding: 50px;
  }
}
.pricing-2-item .item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/pricing-dot.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}
.pricing-2-item .item .title {
  font-size: 40px;
}
.pricing-2-item .item .text {
  font-family: "space_grotesk";
  color: #383838;
  margin-bottom: -8px;
}
.pricing-2-item .item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.pricing-2-item .item ul li {
  font-size: 18px;
  line-height: 50px;
  color: #000;
}
.pricing-2-item .item ul li img {
  padding-right: 12px;
}
@media (max-width: 767px) {
  .pricing-2-item .item ul li img {
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pricing-2-item .item ul li img {
    padding-right: 10px;
  }
}
.pricing-2-item .item ul li:nth-child(4) {
  font-family: "space_grotesk";
}
.pricing-2-item .item ul li:nth-child(4) span {
  font-family: "space_grotesk";
  position: relative;
}
.pricing-2-item .item ul li:nth-child(4) span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -7px;
  height: 2px;
  width: 100%;
  border-bottom: 2.5px dashed #0f1016;
}
.pricing-2-item .item ul li:nth-child(7) {
  font-family: "space_grotesk";
}
.pricing-2-item .item ul li:nth-child(7) span {
  font-family: "space_grotesk";
  position: relative;
}
.pricing-2-item .item ul li:nth-child(7) span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -7px;
  height: 2px;
  width: 100%;
  border-bottom: 2.5px dashed #0f1016;
}
.pricing-2-item .item ul li:nth-child(8) {
  opacity: 0.35;
}
.pricing-2-item .item ul li:nth-child(9) {
  opacity: 0.35;
}
.pricing-2-item .item a {
  width: 100%;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  border-radius: 10px;
  margin-top: 33px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  z-index: 5;
  letter-spacing: 1px;
}
.pricing-2-item .item a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #fad029 0%, #ff564f 100%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
  opacity: 0;
}
.pricing-2-item .item a:hover {
  border: 1px solid transparent;
  color: #fff;
}
.pricing-2-item .item a:hover::before {
  opacity: 1;
}
.pricing-2-item.item-2 .item ul li:nth-child(8) {
  opacity: 1;
}
.pricing-2-item.item-2 .item ul li:nth-child(9) {
  opacity: 1;
}
.pricing-2-item.item-2 .item a {
  border: 1px solid transparent;
  color: #fff;
}
.pricing-2-item.item-2 .item a::before {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
}
.pricing-2-item.item-2 .item a:hover {
  border: 1px solid #000;
  color: #000;
}
.pricing-2-item.item-2 .item a:hover::before {
  opacity: 0;
}
.pricing-2-item.pricing-9-item {
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.15);
}
.pricing-2-item.pricing-9-item::before {
  background: linear-gradient(90deg, #45108a 0%, #10054d 100%);
}
.pricing-2-item.pricing-9-item .item a::before {
  background: linear-gradient(90deg, #dc3daa 0%, #7712c8 100%);
}
.pricing-2-item.pricing-10-item {
  box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.15);
}
.pricing-2-item.pricing-10-item::before {
  background: #2aa38c;
  border-radius: 0;
}
.pricing-2-item.pricing-10-item .item {
  border-radius: 0;
}
.pricing-2-item.pricing-10-item .item .title {
  font-family: "Playfair Display", serif;
}
.pricing-2-item.pricing-10-item .item a {
  background: #fff;
  border-radius: 0px;
}
.pricing-2-item.pricing-10-item .item a::before {
  background: #2aa38c;
}

.pricing-9-area {
  background: #fff;
  padding-bottom: 10px;
}
.pricing-9-area .section-title-9 {
  padding-bottom: 10px;
}
.pricing-9-area.pricing-10-area {
  padding-top: 87px;
}
.pricing-9-area.pricing-10-area .project-title .title {
  font-family: "Playfair Display", serif;
}

.pricing-3-area {
  background: #000;
  padding-top: 100px;
  padding-bottom: 100px;
}
.pricing-3-area.pricing-page-3 .pricing-3-content .title {
  font-family: "space_grotesk";
}
.pricing-3-area .pricing-3-box .title {
  font-family: "space_grotesk";
}
.pricing-3-area.pricing-15-area .pricing-3-content .title {
  font-family: "space_grotesk";
}
.pricing-3-area.pricing-15-area .pricing-3-content .info::before {
  background: #f0323c;
}
.pricing-3-area.pricing-15-area .pricing-3-box a {
  background: #f0323c;
  border: 0;
}
.pricing-3-area.pricing-15-area .pricing-3-box a:hover {
  background: #bf141d;
}
.pricing-3-area.pricing-16-area .pricing-3-content .title {
  font-family: "space_grotesk";
}
.pricing-3-area.pricing-16-area .pricing-3-content .info::before {
  background: #3863f6;
}
.pricing-3-area.pricing-16-area .pricing-3-box a {
  background: #3863f6;
  border: 0;
}
.pricing-3-area.pricing-16-area .pricing-3-box a:hover {
  background: #f6bb38;
}

.pricing-3-content {
  color: #fff;
  padding-left: 100px;
  padding-right: 95px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .pricing-3-content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-3-content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .pricing-3-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.pricing-3-content .title {
  color: #fff;
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  padding-bottom: 29px;
}
.pricing-3-content .text {
  margin-top: 28px;
}
.pricing-3-content .info {
  position: relative;
  padding-left: 23px;
}
.pricing-3-content .info::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 73px;
  width: 3px;
  background: #564fff;
}
.pricing-3-content .info ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pricing-3-content .info ul li {
  color: #fff;
  line-height: 40px;
  margin-top: 13px;
}

.pricing-3-box {
  padding: 43px 40px 40px;
  background: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-3-box {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .pricing-3-box {
    margin-top: 30px;
    padding: 43px 10px 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pricing-3-box {
    padding: 43px 40px 40px;
  }
}
.pricing-3-box .title {
  font-size: 40px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
.pricing-3-box .text {
  font-weight: 300;
  color: #000;
}
.pricing-3-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pricing-3-box ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
  line-height: 50px;
  color: #000;
}
@media (max-width: 767px) {
  .pricing-3-box ul li {
    font-size: 15px;
  }
}
.pricing-3-box ul li span img {
  padding-right: 18px;
}
@media (max-width: 767px) {
  .pricing-3-box ul li span img {
    padding-right: 5px;
  }
}
.pricing-3-box a {
  width: 100%;
  padding: 0;
  line-height: 66px;
  background: #ff564f;
  border: 2px solid #ff564f;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 0;
  margin-top: 40px;
}
.pricing-3-box a:hover {
  background-color: #564fff;
  color: #fff;
  border-color: #564fff;
}

.pricing-7-area {
  padding-top: 86px;
  padding-bottom: 100px;
  background: #000;
}
.pricing-7-area .section-white {
  color: #fff;
}
.pricing-7-area .section-white .title {
  color: #fff;
}

.pricing-12-area {
  padding-top: 91px;
  padding-bottom: 9px;
}
.pricing-12-area .pricing-2-item.pricing-10-item .item .title {
  font-family: "space_grotesk";
}

/*===========================
    11.ARTICLE css 
===========================*/
.article-area {
  padding-top: 88px;
  padding-bottom: 100px;
  background: #f3f3f3;
}
.article-area.article-5-area {
  padding-top: 68px;
}
.article-area.article-5-area .section-title .title {
  margin-bottom: 0;
}

.article-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.article-item .article-top {
  padding: 30px 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-item .article-top {
    padding: 30px 15px;
  }
}
.article-item .article-top span {
  font-size: 22px;
  line-height: 30px;
  color: #000;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-item .article-top span {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .article-item .article-top span {
    font-size: 20px;
  }
}
.article-item .article-thumb {
  position: relative;
}
.article-item .article-thumb .date {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 54px;
  height: 56px;
  background: #ffab4a;
  text-align: center;
  padding-top: 8px;
}
.article-item .article-thumb .date .title {
  margin-bottom: 0;
  font-size: 20px;
  color: #fff;
}
.article-item .article-thumb .date span {
  font-size: 14px;
  font-family: "space_grotesk";
  color: #fff;
  display: block;
  line-height: 22px;
  margin-top: -6px;
}
.article-item .article-thumb img {
  width: 100%;
}
.article-item .article-content {
  padding: 25px 25px 35px 25px;
  font-weight: 300;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-item .article-content {
    font-size: 15px;
  }
}
.article-item .article-content a {
  font-family: "space_grotesk";
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 3px;
  display: inline-block;
  color: #000;
  position: relative;
  font-weight: 400;
}
.article-item .article-content a::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -4px;
  height: 1px;
  width: 100%;
  background: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.article-item .article-content a:hover {
  color: #ffab4a;
}
.article-item .article-content a:hover::before {
  background: #ffab4a;
}
.article-item.article-6-item .article-thumb .date {
  background: #564fff;
}
.article-item.article-6-item .article-content a:hover {
  color: #564fff;
}
.article-item.article-6-item .article-content a:hover::before {
  background: #564fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .article-item.item-3 {
    display: none;
  }
}

.article-2-area {
  padding-top: 100px;
  padding-bottom: 100px;
}
.article-2-area.article-7-area {
  padding-top: 86px;
}
.article-2-area.article-11-area {
  background: #f5f5f5;
  padding-top: 92px;
}
.article-2-area.article-15-area {
  padding-top: 86px;
}
.article-2-area.article-15-area .article-2-item .article-content .date .item {
  background: #f0323c;
}
.article-2-area.article-15-area
  .article-2-item
  .article-content
  > a
  .icon::before {
  background: #f0323c;
}

.article-2-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.article-2-item .article-thumb {
  padding: 15px;
}
.article-2-item .article-thumb img {
  width: 100%;
}
.article-2-item .article-content {
  padding: 0 25px 20px;
}
.article-2-item .article-content .date {
  position: relative;
}
.article-2-item .article-content .date > span {
  padding-left: 70px;
  display: inline-block;
  color: #000;
}
.article-2-item .article-content .date .item {
  position: absolute;
  left: 0;
  bottom: 6px;
  width: 54px;
  height: 56px;
  background: linear-gradient(330deg, #ff564f 0%, #fad029 80%);
  text-align: center;
  padding-top: 8px;
  border-radius: 5px;
}
.article-2-item .article-content .date .item .title {
  margin-bottom: 0;
  font-size: 20px;
  color: #fff;
}
.article-2-item .article-content .date .item span {
  font-size: 14px;
  font-family: "space_grotesk";
  color: #fff;
  display: block;
  line-height: 22px;
  margin-top: -6px;
}
.article-2-item .article-content > .title {
  font-size: 22px;
  line-height: 30px;
  font-family: "space_grotesk";
  padding-right: 15px;
  margin-top: 20px;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-2-item .article-content > .title {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .article-2-item .article-content > .title {
    font-size: 19px;
  }
}
@media (max-width: 767px) {
  .article-2-item .article-content > .title {
    font-size: 17px;
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .article-2-item .article-content > .title {
    font-size: 22px;
    padding-right: 0;
  }
}
.article-2-item .article-content > .title a {
  color: #000;
}
.article-2-item .article-content > a {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "space_grotesk";
  color: #383838;
  display: flex;
  align-items: center;
}
.article-2-item .article-content > a .icon {
  height: 24px;
  width: 24px;
  background: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  display: inline-block;
  transform: rotate(45deg);
  margin-left: 6px;
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -4px;
}
.article-2-item .article-content > a .icon::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  height: 100%;
  width: 100%;
  z-index: -1;
  background: linear-gradient(330deg, #ff564f 0%, #fad029 80%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 50%;
}
.article-2-item:hover {
  background: #fff;
  border-color: #fff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}
.article-2-item:hover .article-content a .icon::before {
  transform: translate(-50%, -50%) scale(1);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .article-2-item.item-3 {
    display: none;
  }
}
.article-2-item.article-4-item .article-content .date .item {
  background: linear-gradient(#2895dd 0%, #6249ef 100%);
  border-radius: 0;
}
.article-2-item.article-4-item .article-content > a .icon::before {
  background: linear-gradient(#2895dd 0%, #6249ef 100%);
}
.article-2-item.article-7-item .article-content .date .item {
  background: #2aa38c;
}
.article-2-item.article-7-item .article-content > a .icon::before {
  background: #2aa38c;
}
.article-2-item.article-post-item .article-content .date .item {
  background: #ffab4a;
}
.article-2-item.article-post-item .article-content > a .icon::before {
  background: #ffab4a;
}

.article-3-area {
  background: #fff;
  padding-top: 91px;
  padding-bottom: 75px;
}
.article-3-area.article-16-area .article-3-title .title {
  font-family: "space_grotesk";
}
.article-3-area.article-16-area .article-3-list .item .content .title {
  font-family: "space_grotesk";
}
.article-3-area.article-16-area .article-3-item .article-content .title {
  font-family: "space_grotesk";
}

.article-3-title {
  font-weight: 300;
}
.article-3-title .title {
  font-size: 40px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
.article-3-title .text {
  margin-left: -45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .article-3-title .text {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .article-3-title .text {
    margin-left: 0;
  }
}

.article-3-item .article-thumb img {
  width: 100%;
}
.article-3-item .article-content {
  margin-top: 23px;
  color: #000;
  font-weight: 300;
}
.article-3-item .article-content span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ff564f;
}
.article-3-item .article-content span span {
  text-transform: capitalize;
  color: #000;
  margin-left: 23px;
}
.article-3-item .article-content .title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  font-family: "Playfair Display", serif;
  margin-top: 5px;
  margin-bottom: 23px;
}

.article-3-list {
  margin-left: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-3-list {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .article-3-list {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .article-3-list {
    margin-left: 0;
  }
}
.article-3-list .item .thumb {
  min-width: 165px;
}
@media (max-width: 767px) {
  .article-3-list .item .thumb {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .article-3-list .item .thumb {
    width: 165px;
  }
}
@media (max-width: 767px) {
  .article-3-list .item .thumb img {
    width: 100%;
  }
}
.article-3-list .item .content {
  margin-left: 30px;
  font-weight: 300;
  color: #000;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-3-list .item .content {
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .article-3-list .item .content {
    margin-left: 0;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .article-3-list .item .content {
    margin-left: 30px;
    margin-top: 0px;
  }
}
.article-3-list .item .content .title {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  font-family: "Playfair Display", serif;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-3-list .item .content .title {
    font-size: 17px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .article-3-list .item .content p {
    font-size: 16px;
  }
}

.artical-4-area {
  background: #f5f5f5;
  padding-top: 88px;
  padding-bottom: 100px;
}

.article-8-item .article-thumb .date {
  background: #ff564f;
}
.article-8-item .article-content a:hover {
  color: #ff564f;
}

.article-9-area {
  padding-top: 90px;
}

.article-9-item .article-content .date .item {
  background: linear-gradient(90deg, #dc3daa 0%, #7712c8 100%);
}
.article-9-item .article-content > a .icon::before {
  background: linear-gradient(90deg, #dc3daa 0%, #7712c8 100%);
}

.article-11-item .article-content .date .item {
  background: #564fff;
}
.article-11-item .article-content > a .icon::before {
  background: #564fff;
}

.article-post-area {
  background: #f5f5f5;
  padding-top: 88px;
  padding-bottom: 100px;
}
.article-post-area .article-post-title .title {
  font-size: 40px;
}

/*===========================
    12.FOOTER css 
===========================*/
.footer-area {
  background: #000;
  padding-top: 100px;
}

.footer-top {
  border-bottom: 2px solid #383838;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .footer-top .footer-social {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-top .footer-social {
    margin-top: 0px;
  }
}
.footer-top .footer-social ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.footer-top .footer-social ul li {
  display: inline-block;
}
.footer-top .footer-social ul li a {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #383838;
  border-radius: 2px;
  display: inline-block;
  margin-right: 5px;
  color: #e0e0e0;
}
.footer-top .footer-social ul li a:hover {
  background: #ffab4a;
  color: #fff;
}
.footer-top.footer-top-4 .footer-social ul li a {
  position: relative;
  z-index: 5;
}
.footer-top.footer-top-4 .footer-social ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(#2895dd 0%, #6249ef 100%);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-top.footer-top-4 .footer-social ul li a:hover::before {
  opacity: 1;
}
.footer-top.footer-top-5 .footer-social ul li a {
  position: relative;
  z-index: 5;
}
.footer-top.footer-top-5 .footer-social ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #ff564f;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-top.footer-top-5 .footer-social ul li a:hover::before {
  opacity: 1;
}
.footer-top.footer-top-9 .footer-social ul li a {
  position: relative;
  z-index: 5;
}
.footer-top.footer-top-9 .footer-social ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #dc3daa 0%, #7712c8 100%);
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-top.footer-top-9 .footer-social ul li a:hover::before {
  opacity: 1;
}
.footer-top.footer-top-10 .footer-social ul li a {
  position: relative;
  z-index: 5;
}
.footer-top.footer-top-10 .footer-social ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #2aa38c;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-top.footer-top-10 .footer-social ul li a:hover::before {
  opacity: 1;
}

.footer-item {
  padding-top: 58px;
}
.footer-item .footer-about {
  color: #fff;
  padding-right: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-item .footer-about {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-item .footer-about {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .footer-item .footer-about {
    padding-right: 0;
  }
}
.footer-item .footer-about .title {
  color: #fff;
  font-size: 22px;
  font-family: "space_grotesk";
  margin-bottom: 37px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-item .footer-list.ml-95 {
    margin-left: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-item .footer-list.ml-95 {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .footer-item .footer-list.ml-95 {
    margin-left: 0px;
  }
}
.footer-item .footer-list .title {
  color: #fff;
  font-size: 22px;
  font-family: "space_grotesk";
  margin-bottom: 37px;
}
.footer-item .footer-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-item .footer-list ul li a {
  color: #fff;
  line-height: 36px;
  font-family: "space_grotesk";
}
.footer-item .footer-list ul li a:hover {
  color: #ffab4a;
}
.footer-item .footer-list.footer-list-4 ul li a:hover {
  color: #564fff;
}
.footer-item .footer-list.footer-list-5 ul li a:hover {
  color: #ff564f;
}
.footer-item .footer-list.footer-list-9 ul li a:hover {
  color: #dc3daa;
}
.footer-item .footer-list.footer-list-10 ul li a:hover {
  color: #2aa38c;
}
.footer-item .footer-list.footer-list-11 ul li a:hover {
  color: #564fff;
}
.footer-item .footer-info .title {
  color: #fff;
  font-size: 22px;
  font-family: "space_grotesk";
  margin-bottom: 37px;
}
.footer-item .footer-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-item .footer-info ul li {
  padding-left: 31px;
  color: #e0e0e0;
  position: relative;
  padding-bottom: 30px;
}
.footer-item .footer-info ul li i {
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 20px;
  color: #ffab4a;
}
.footer-item .footer-info.footer-info-4 ul li i {
  color: #564fff;
}
.footer-item .footer-info.footer-info-5 ul li i {
  color: #ff564f;
}
.footer-item .footer-info.footer-info-9 ul li i {
  color: #dc3daa;
}
.footer-item .footer-info.footer-info-10 ul li i {
  color: #2aa38c;
}

.footer-copyright {
  color: #fff;
  font-family: "space_grotesk";
  margin-top: 30px;
  margin-bottom: 30px;
}

.footer-2-area {
  padding-top: 0;
}
.footer-2-area .footer-2-top {
  padding: 42px 0;
  background: #383838;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-2-area .footer-2-top .footer-logo {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer-2-area .footer-2-top .footer-logo {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer-2-area .footer-info {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-2-area .footer-info.ml-60 {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-2-area .footer-info.ml-60 {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .footer-2-area .footer-info.ml-60 {
    margin-left: 0px;
  }
}
.footer-2-area .footer-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-2-area .footer-info ul li {
  color: #e0e0e0;
  padding-left: 30px;
  position: relative;
}
.footer-2-area .footer-info ul li img {
  position: absolute;
  left: 0;
  top: 5px;
}
.footer-2-area .footer-copyright {
  margin-bottom: 50px;
  margin-top: 15px;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .footer-2-area .footer-copyright {
    justify-content: center;
    margin-top: 40px;
  }
}
.footer-2-area .footer-copyright ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.footer-2-area .footer-copyright ul li {
  margin-right: 10px;
}
.footer-2-area .footer-copyright ul li a {
  background: #383833 8px;
  display: inline-block;
  border-radius: 2px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 54px;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 5;
}
.footer-2-area .footer-copyright ul li a::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  height: 100%;
  width: 100%;
  background: linear-gradient(330deg, #fad029 0%, #ff564f 80%);
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 2px;
}
.footer-2-area .footer-copyright ul li a:hover::before {
  transform: translate(-50%, -50%) scale(1);
}
.footer-2-area .footer-copyright p {
  margin-bottom: 0;
  margin-left: 80px;
}
@media (max-width: 767px) {
  .footer-2-area .footer-copyright p {
    margin-left: 0;
    padding-top: 20px;
  }
}
.footer-2-area .footer-copyright.footer-copyright-11 ul li a::before {
  background: #564fff;
}
.footer-2-area.footer-7-area .footer-copyright ul li a::before {
  background: linear-gradient(90deg, #13bf9e 0%, #108971 100%);
}
.footer-2-area.footer-7-area .footer-item .footer-list ul li a:hover {
  color: #2aa38c;
}
.footer-2-area.footer-7-area .footer-newsletter-item {
  background: linear-gradient(0deg, #13bf9e 0%, #108971 100%);
}

.footer-newsletter-item {
  background: linear-gradient(330deg, #fad029 0%, #ff564f 80%);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  z-index: 5;
}
.footer-newsletter-item .dot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.footer-newsletter-item .dot img {
  width: 100%;
}
.footer-newsletter-item .title {
  color: #fff;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-newsletter-item .title {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .footer-newsletter-item .title {
    font-size: 22px;
  }
}
.footer-newsletter-item .input-box input {
  width: 100%;
  background: #383838;
  border-radius: 8px;
  line-height: 60px;
  padding-left: 20px;
  border: 0;
  color: #fff;
}
.footer-newsletter-item .input-box input::placeholder {
  opacity: 1;
  color: #fff;
}
.footer-newsletter-item .input-box button {
  width: 100%;
  background: #fff;
  color: #000;
  line-height: 64px;
  border: 0;
  border-radius: 10px;
  margin-top: 10px;
}
.footer-newsletter-item.footer-newsletter-item-11 {
  background: #564fff;
}

.footer-3-area {
  background: #f5f5f5;
}
.footer-3-area .go-top {
  margin-top: 242px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-3-area .go-top {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .footer-3-area .go-top {
    margin-top: 40px;
  }
}
.footer-3-area .go-top .back-to-top {
  position: static;
  height: auto;
  width: auto;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
}
.footer-3-area .go-top .back-to-top i {
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  background: rgba(22, 29, 51, 0.15);
  border-radius: 50%;
  font-size: 14px;
  transform: rotate(45deg);
}
.footer-3-area .go-top .back-to-top span {
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 15px;
  opacity: 0.5;
  color: #383838;
}

.footer-3-item {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .footer-3-item {
    margin-right: 0;
  }
}
.footer-3-item .footer-work > span {
  font-size: 14px;
  color: #383838;
  border-bottom: 2px solid #dedfe1;
  display: inline-block;
  padding-bottom: 12px;
  display: block;
}
.footer-3-item .footer-work .item {
  padding-top: 20px;
}
.footer-3-item .footer-work .item span {
  font-size: 15px;
  color: #000;
}
.footer-3-item .footer-work .item .title {
  font-size: 20px;
  font-weight: 500;
  padding-top: 6px;
  line-height: 25px;
}
.footer-3-item .footer-work .item ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 7px;
}
.footer-3-item .footer-work .item ul li {
  display: inline-block;
}
.footer-3-item .footer-work .item ul li a {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 45px;
  border: 1px solid #33415c;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  margin-right: 7px;
}
.footer-3-item .footer-work.item-2 {
  padding-top: 45px;
}
.footer-3-item .footer-work.item-2 .item span {
  font-size: 15px;
}
.footer-3-item .footer-work.item-2 .item .title {
  padding-right: 50px;
}

.subscribe-area {
  background: -webkit-linear-gradient(#2895dd 0%, #6249ef 100%);
  background: -o-linear-gradient(#2895dd 0%, #6249ef 100%);
  background: linear-gradient(#2895dd 0%, #6249ef 100%);
  padding-top: 60px;
  padding-bottom: 60px;
}
.subscribe-area.subscribe-6-area {
  background: #564fff;
}
.subscribe-area.subscribe-9-area {
  background: linear-gradient(90deg, #10054d 0%, #45108a 100%);
}
.subscribe-area.subscribe-9-area .subscribe-item .title {
  font-family: "space_grotesk";
}
.subscribe-area.subscribe-10-area {
  background: #2aa38c;
  margin-top: 97px;
}
.subscribe-area.subscribe-10-area .subscribe-item .title {
  font-family: "space_grotesk";
}
.subscribe-area.subscribe-11-area {
  background: #564fff;
}
.subscribe-area.subscribe-11-area .subscribe-item .title {
  font-family: "space_grotesk";
}
.subscribe-area.subscribe-13-area {
  background: #ffab4a;
}
.subscribe-area.subscribe-13-area .subscribe-item .title {
  font-family: "space_grotesk";
}
.subscribe-area.subscribe-15-area {
  background: #f0323c;
}
.subscribe-area.subscribe-15-area .subscribe-item .title {
  font-family: "space_grotesk";
}

.subscribe-item {
  color: #fff;
}
.subscribe-item .title {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  margin-bottom: 21px;
}
@media (max-width: 767px) {
  .subscribe-item .title {
    font-size: 32px;
  }
}

.subscribe-form {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .subscribe-form {
    margin-top: 20px;
  }
}
.subscribe-form input {
  width: 100%;
  height: 70px;
  padding-left: 30px;
  border: 0;
  background: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  line-height: 50px;
  background-color: transparent;
  border: 0;
  z-index: 5;
  color: #fff;
  width: 130px;
  text-align: center;
  padding: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.subscribe-form button::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: linear-gradient(to right, #6249ef 0%, #2895dd 50%, #6249ef 100%);
  background-size: 200% auto;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.subscribe-form button:hover {
  color: #fff;
}
.subscribe-form button:hover::before {
  background-position: right center;
}
.subscribe-form.subscribe-6-form button {
  background: #ff564f;
  border-radius: 0;
}
.subscribe-form.subscribe-6-form button:hover {
  background: #564fff;
}
.subscribe-form.subscribe-6-form button::before {
  display: none;
}
.subscribe-form.subscribe-9-form button {
  border-radius: 5px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  padding: 0 28px;
}
.subscribe-form.subscribe-9-form button::before {
  background: linear-gradient(to right, #dc3daa 0%, #7712c8 50%, #dc3daa 100%);
  background-size: 200% auto;
}
.subscribe-form.subscribe-9-form button:hover::before {
  background-position: right center;
}
.subscribe-form.subscribe-10-form button {
  border-radius: 0px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  padding: 0 28px;
}
.subscribe-form.subscribe-10-form button::before {
  background: #2aa38c;
}
.subscribe-form.subscribe-13-form input {
  border-radius: 5px;
}
.subscribe-form.subscribe-13-form button {
  border-radius: 5px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  padding: 0 28px;
  background: #2aa38c;
}
.subscribe-form.subscribe-13-form button::before {
  display: none;
}
.subscribe-form.subscribe-11-form ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 27px;
}
.subscribe-form.subscribe-11-form ul li {
  display: inline-block;
}
.subscribe-form.subscribe-11-form ul li a {
  background: #fad029;
  color: #fff;
  text-transform: uppercase;
  border-color: #fad029;
  border-radius: 2px;
  line-height: 60px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
.subscribe-form.subscribe-11-form ul li a:hover {
  background: #fff;
  border-color: #fff;
  color: #564fff;
}
.subscribe-form.subscribe-11-form ul li a.main-btn-2 {
  background: #fff;
  border-color: #fff;
  margin-left: 5px;
  color: #564fff;
}
@media (max-width: 767px) {
  .subscribe-form.subscribe-11-form ul li a.main-btn-2 {
    margin-left: 0;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form.subscribe-11-form ul li a.main-btn-2 {
    margin-left: 28px;
    margin-top: 0px;
  }
}
.subscribe-form.subscribe-11-form ul li a.main-btn-2:hover {
  background: #fad029;
  border-color: #fad029;
  color: #fff;
}
.subscribe-form.subscribe-11-form.subscribe-15-form ul li a {
  background: #bf141d;
  border-color: #bf141d;
  color: #fff;
}
.subscribe-form.subscribe-11-form.subscribe-15-form ul li a:hover {
  background: #fff;
  border-color: #fff;
  color: #bf141d;
}
.subscribe-form.subscribe-11-form.subscribe-15-form ul li a.main-btn-2 {
  background: #fff;
  border-color: #fff;
  color: #bf141d;
}
.subscribe-form.subscribe-11-form.subscribe-15-form ul li a.main-btn-2:hover {
  background: #bf141d;
  border-color: #bf141d;
  color: #fff;
}

/*==== BACK TO TOP ====*/
.back-to-top {
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #ffab4a;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  cursor: pointer;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.09);
}
.back-to-top i {
  color: #fff;
}
.back-to-top:hover {
  background-color: #ffab4a;
}
.back-to-top.back-to-top-2 {
  background: #2aa38c;
}
.back-to-top.back-to-top-4 {
  background: linear-gradient(#2895dd 0%, #6249ef 100%);
}
.back-to-top.back-to-top-7 {
  background: #2aa38c;
}
.back-to-top.back-to-top-6 {
  background: #ff564f;
}
.back-to-top.back-to-top-9 {
  background: linear-gradient(90deg, #dc3daa 0%, #7712c8 100%);
}
.back-to-top.back-to-top-10 {
  background: #2aa38c;
}
.back-to-top.back-to-top-11 {
  background: #564fff;
}
.back-to-top.back-to-top-16 {
  background: #3863f6;
}

.footer-15-area {
  padding-top: 63px;
}
.footer-15-area .footer-copyright-area {
  background: #f5f5f5;
  margin-top: 57px;
}
.footer-15-area .footer-copyright-area .footer-copyright-item {
  line-height: 60px;
}
.footer-15-area .footer-copyright-area .footer-copyright-item p {
  margin: 0;
  font-size: 15px;
}
@media (max-width: 767px) {
  .footer-15-area .footer-copyright-area .footer-copyright-item p {
    font-size: 13px;
  }
}

.footer-15-about .title {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  margin-bottom: 21px;
}
.footer-15-about p {
  padding-bottom: 25px;
}
.footer-15-about .info-item .item {
  margin-top: 30px;
}
.footer-15-about .info-item .item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-15-about .info-item .item ul li a {
  color: #000;
  padding-left: 30px;
  position: relative;
}
.footer-15-about .info-item .item ul li a i {
  position: absolute;
  left: 0;
  top: 3px;
  color: #f0323c;
  font-size: 20px;
}
.footer-15-about .social {
  padding-top: 30px;
}
.footer-15-about .social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-15-about .social ul li a {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  color: #f0323c;
  background: #f5f5f5;
  border-radius: 3px;
  display: inline-block;
  margin-right: 10px;
}
.footer-15-about .social ul li a:hover {
  background: #f0323c;
  color: #fff;
}
.footer-15-about.footer-16-about .info-item .item ul li a i {
  color: #3863f6;
}
.footer-15-about.footer-16-about .social ul li a {
  color: #3863f6;
}
.footer-15-about.footer-16-about .social ul li a:hover {
  background: #3863f6;
  color: #fff;
}

.footer-15-post .footer-post-title .title {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  margin-bottom: 21px;
}
.footer-15-post .footer-post-item .item {
  padding-left: 115px;
  position: relative;
  margin-top: 17px;
}
.footer-15-post .footer-post-item .item img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.footer-15-post .footer-post-item .item .title {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 20px;
  margin-bottom: 0;
}
.footer-15-post .footer-post-item .item span {
  font-size: 12px;
  line-height: 17px;
}
.footer-15-post .popular-post ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.footer-15-post .popular-post ul li {
  margin-right: 15px;
  margin-top: 15px;
}

/*===========================
    13.FUN FACTS css 
===========================*/
.fun-facts-area {
  padding-top: 58px;
  padding-bottom: 94px;
  background: #fff;
}

@media (max-width: 767px) {
  .fun-facts-item {
    text-align: center;
  }
}
.fun-facts-item .icon {
  height: 76px;
}
.fun-facts-item .title {
  font-size: 60px;
  font-family: "space_grotesk";
  margin-top: 25px;
  margin-bottom: 1px;
  font-weight: 500;
}
.fun-facts-item > span {
  font-size: 20px;
}

.fun-facts-3-area {
  background: #f5f5f5;
}
.fun-facts-3-area.fun-facts-4-area {
  background: #fff;
}

.fun-facts-3-item {
  background: #fff;
  padding: 40px 0 35px;
}
.fun-facts-3-item .title {
  font-size: 54px;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  margin-top: 19px;
}
.fun-facts-3-item > span {
  font-size: 16px;
}
.fun-facts-3-item.fun-facts-4-item {
  box-shadow: 0px 20px 90px rgba(0, 0, 0, 0.08);
}

/*===========================
    14.REVIEW css 
===========================*/
.review-area {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #fff;
}

.review-item {
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .review-item {
    display: block;
  }
}
.review-item .review-content {
  margin-right: 68px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .review-item .review-content {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .review-item .review-content {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .review-item .review-content {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.review-item .review-content .title {
  font-size: 70px;
  font-weight: 500;
  color: #33415c;
  margin-bottom: 3px;
}
.review-item .review-content .title span {
  font-size: 22px;
  margin-left: -11px;
}
.review-item .review-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.review-item .review-content ul li {
  display: inline-block;
}
.review-item .review-content ul li i {
  font-size: 17px;
  color: #ffab4a;
}
.review-item .review-content > span {
  color: #383838;
}
.review-item .review-content > span span {
  color: #ff564f;
}

.review-box ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.review-box ul > li {
  text-align: center;
  height: 142px;
  width: 165px;
  background: #f5f5f5;
  border-radius: 5px;
  padding-top: 25px;
  margin: 0 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .review-box ul > li {
    width: 135px;
  }
}
@media (max-width: 767px) {
  .review-box ul > li {
    width: 150px;
    margin-bottom: 30px;
  }
}
.review-box ul > li span {
  display: block;
  font-size: 13px;
  padding-top: 13px;
  margin-bottom: -8px;
}
.review-box ul > li i {
  color: #ffab4a;
  font-size: 10px;
}

/*===========================
    15.UPDATE NEWS css 
===========================*/
.update-news-area {
  background: #fff;
}
.update-news-area.update-news-12-area {
  padding-top: 96px;
}

.update-news-item {
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .update-news-item .update-news-content {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .update-news-item .update-news-content {
    margin-top: 30px;
  }
}
.update-news-item .update-news-content > span {
  background: rgba(86, 79, 255, 0.1);
  line-height: 43px;
  width: 130px;
  padding: 0;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  color: #564fff;
  font-family: "space_grotesk";
}
.update-news-item .update-news-content .title {
  font-size: 30px;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  line-height: 36px;
  margin-top: 30px;
  margin-bottom: 28px;
}
@media (max-width: 767px) {
  .update-news-item .update-news-content .title {
    font-size: 25px;
  }
}
.update-news-item .update-news-content .text {
  font-family: "space_grotesk";
}
.update-news-item .update-news-content a {
  margin-top: 48px;
  display: inline-block;
  font-size: 14px;
  font-family: "space_grotesk";
  text-transform: uppercase;
  color: #707070;
}
.update-news-item .update-news-content a span {
  height: 30px;
  width: 30px;
  background: #ff564f;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  margin-left: 12px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .update-news-item .update-news-thumb img {
    width: 100%;
  }
}
.update-news-item.update-news-item-12 .update-news-content > span {
  background: rgba(42, 163, 140, 0.1);
  color: #2aa38c;
}
.update-news-item.update-news-item-12 .update-news-content .title {
  font-family: "space_grotesk";
  font-weight: 700;
}
.update-news-item.update-news-item-12 .update-news-content a span {
  background: #2aa38c;
}
.update-news-item.update-news-item-12 .update-news-thumb img {
  border-radius: 10px;
}

/*===========================
    16.contact us css 
===========================*/
.contact-us-area {
  position: relative;
  z-index: 10;
  padding-top: 90px;
  padding-bottom: 100px;
}
.contact-us-area .section__bg {
  background-image: url(../images/contact-us-bg.png);
}
.contact-us-area .section__bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.4;
}
.contact-us-area .contact-us-title {
  padding-bottom: 48px;
}
.contact-us-area .contact-us-title .title {
  color: #fff;
  font-size: 54px;
  line-height: 62px;
  font-family: "Playfair Display", serif;
}
@media (max-width: 767px) {
  .contact-us-area .contact-us-title .title {
    font-size: 50px;
  }
}
.contact-us-area.contact-us-page {
  padding-top: 100px;
}
.contact-us-area.contact-us-page .contact-us-box {
  margin-right: 0;
}
.contact-us-area.contact-us-page .contact-us-box .title {
  font-family: "space_grotesk";
}
.contact-us-area.contact-us-page .contact-us-box .input-box button {
  background: #ffab4a;
  border-color: #ffab4a;
}
.contact-us-area.contact-us-page .contact-us-box .input-box button:hover {
  background-color: transparent;
  color: #ffab4a;
}

.contact-us-box {
  background: #fff;
  padding: 50px 45px;
  margin-right: 80px;
  font-weight: 300;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-us-box {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .contact-us-box {
    margin-right: 0;
    padding: 50px 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-us-box {
    padding: 50px 45px;
  }
}
.contact-us-box .title {
  font-size: 40px;
  line-height: 48px;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  padding-left: 5px;
}
.contact-us-box p {
  padding-left: 5px;
  margin-bottom: 33px;
}
.contact-us-box .input-box {
  margin: 5px;
}
.contact-us-box .input-box input {
  width: 100%;
  background: #f5f5f5;
  border: 0;
  padding-left: 20px;
  line-height: 60px;
  color: #33415c;
  font-size: 16px;
}
.contact-us-box .input-box textarea {
  width: 100%;
  background: #f5f5f5;
  border: 0;
  padding-left: 20px;
  height: 120px;
  color: #33415c;
  font-size: 16px;
  resize: none;
  padding-top: 15px;
}
.contact-us-box .input-box button {
  width: 100%;
  height: 62px;
  width: 100%;
  background: #ff564f;
  border-color: #ff564f;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all linear 0.3s;
}
.contact-us-box .input-box button:hover {
  background-color: #564fff;
  color: #fff;
  border-color: #564fff;
}

.checkbox_common {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 5px;
}

.checkbox_common li label {
  color: #333;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
  color: #000;
  opacity: 0.8;
}
.checkbox_common li label a {
  text-decoration: underline;
}

.checkbox_common li input[type="checkbox"] {
  display: none;
}

.checkbox_common li input[type="checkbox"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #f5f5f5;
  position: relative;
  background: #f5f5f5;
}

.checkbox_common li input[type="checkbox"] + label span:before {
  color: #fff;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  position: absolute;
  text-align: center;
  left: 3px;
  top: -7px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.checkbox_common li input[type="checkbox"]:checked + label span {
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.checkbox_common li input[type="checkbox"]:checked + label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.checkbox_common li input[type="checkbox"] + label span,
.checkbox_common li input[type="checkbox"]:checked + label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.checkbox_style5 li input[type="checkbox"] + label span::before {
  color: #ff564f;
}

.checkbox_style5 li input[type="checkbox"] + label span {
  border-radius: 0px;
}

.checkbox_style5 li input[type="checkbox"]:checked + label span {
  background: #f5f5f5;
}

.contact-map {
  margin-bottom: -10px;
}
.contact-map iframe {
  width: 100%;
  height: 500px;
}

/*===========================
    17.PROJECT css 
===========================*/
.project-area {
  background: #fff;
  padding-top: 85px;
  padding-bottom: 100px;
}

.project-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  padding-bottom: 12px;
  padding: 0 40px;
  color: #000;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-title {
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-title {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .project-title {
    padding: 0;
    font-size: 16px;
  }
}
.project-title .title {
  font-size: 50px;
  font-weight: 700;
  line-height: 62px;
  margin-bottom: 21px;
  font-family: "Playfair Display", serif;
}
@media (max-width: 767px) {
  .project-title .title {
    font-size: 36px;
  }
}
.project-title.title-white {
  color: #fff;
}
.project-title.title-white .title {
  color: #fff;
}

.project-item {
  position: relative;
  overflow: hidden;
}
.project-item .project-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(98, 73, 239, 0.85) 0%,
    rgba(40, 149, 221, 0.85) 100%
  );
  padding-left: 35px;
  padding-top: 34px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-item .project-overlay {
    padding-left: 20px;
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .project-item .project-overlay {
    padding-left: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-item .project-overlay {
    padding-left: 35px;
    padding-top: 34px;
  }
}
.project-item .project-overlay .title {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  margin-top: 4px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-item .project-overlay .title {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
.project-item .project-overlay span {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.project-item .project-overlay a {
  position: absolute;
  left: 35px;
  bottom: 30px;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 54px;
  background: #fff;
  border-radius: 50%;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-item .project-overlay a {
    left: 20px;
  }
}
.project-item > img {
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.project-item:hover img {
  transform: scale(1.1);
}
.project-item:hover .project-overlay {
  opacity: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-item.item-9 {
    display: none;
  }
}
@media (max-width: 767px) {
  .project-item.item-9 {
    display: none;
  }
}
.project-item.project-14-item .project-overlay {
  background: rgba(0, 0, 0, 0.7);
}

.project-5-area {
  background: #f5f5f5;
  padding-top: 90px;
  padding-bottom: 100px;
}
.project-5-area .project-title .title {
  font-size: 54px;
  line-height: 62px;
  font-family: "space_grotesk";
  font-weight: bold;
}

.project-5-item {
  position: relative;
}
.project-5-item .project-overlay {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background: #fff;
  padding: 28px 30px;
  border-radius: 10px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-5-item .project-overlay {
    padding: 28px 20px;
  }
}
@media (max-width: 767px) {
  .project-5-item .project-overlay {
    padding: 28px 20px;
    left: 0;
    bottom: 0;
  }
}
.project-5-item .project-overlay .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .project-5-item .project-overlay .title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .project-5-item .project-overlay .title {
    font-size: 18px;
  }
}
.project-5-item .project-overlay span {
  font-size: 14px;
  color: #ff564f;
}
.project-5-item img {
  width: 100%;
}
.project-5-item:hover .project-overlay {
  opacity: 1;
}

.project-8-area {
  background: #f5f5f5;
  padding-top: 86px;
  padding-bottom: 100px;
}
.project-8-area .project-btn a {
  border-radius: 0;
  background: #ff564f;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  border-color: #ff564f;
  line-height: 60px;
  padding: 0 40px;
}
.project-8-area .project-btn a:hover {
  background-color: transparent;
  color: #ff564f;
}

/*===========================
    18.KEY FEATURE css 
===========================*/
.key-feature-area {
  padding-bottom: 100px;
  background: #fff;
}

.key-feature-content {
  padding-left: 100px;
  padding-right: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .key-feature-content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .key-feature-content {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 40px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .key-feature-content {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    margin-top: 40px;
  }
}
.key-feature-content .title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
}
.key-feature-content .text {
  font-weight: 300;
  color: #000;
  padding-top: 6px;
}
.key-feature-content a {
  margin-top: 48px;
  display: inline-block;
  font-size: 14px;
  font-family: "space_grotesk";
  text-transform: uppercase;
  color: #707070;
}
.key-feature-content a span {
  height: 30px;
  width: 30px;
  background: #2aa38c;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  margin-left: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .key-feature-thumb {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .key-feature-thumb {
    text-align: center;
  }
}

.key-features-9-area {
  padding-top: 90px;
  background: #fff;
}

/*===========================
    19.VIDEO css 
===========================*/
.video-area {
  position: relative;
  z-index: 10;
  padding: 200px 0 165px;
}
.video-area .section__bg {
  background-image: url(../images/video-bg.jpg);
  background-attachment: fixed;
}
.video-area .section__bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(19, 191, 158, 0.8) 0%,
    rgba(16, 137, 113, 0.8) 100%
  );
}

.video-content a {
  display: inline-block;
  height: 150px;
  width: 150px;
  text-align: center;
  line-height: 150px;
  border-radius: 50%;
  background: #fff;
  font-size: 40px;
  color: #2aa38c;
}
.video-content span {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
  display: block;
  margin-top: 61px;
  padding: 0 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .video-content span {
    padding: 0;
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .video-content span {
    padding: 0;
    font-size: 18px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video-content span {
    font-size: 30px;
    line-height: 40px;
  }
}

/*===========================
    20.SCREENSHORT css 
===========================*/
.screenshot-area {
  background: #f5f8fd;
  padding-top: 86px;
  padding-bottom: 94px;
}

.screenshot-item {
  margin-top: 110px;
  margin-bottom: 140px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.screenshot-item img {
  width: 100%;
  box-shadow: 0px 20px 18px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  overflow: hidden;
}

.screenshot-active .slick-current .screenshot-item {
  transform: scale(1.35);
  position: relative;
  z-index: 5;
  box-shadow: none;
  margin-top: 110px;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .screenshot-active .slick-current .screenshot-item {
    transform: scale(1.3);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .screenshot-active .slick-current .screenshot-item {
    transform: scale(1);
  }
}
@media (max-width: 767px) {
  .screenshot-active .slick-current .screenshot-item {
    transform: scale(1);
  }
}
.screenshot-active .slick-current .screenshot-item::before {
  content: "";
  position: absolute;
  top: 48%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-image: url(../images/case.png);
  background-position: center center;
  background-repeat: no-repeat;
  width: 280px;
  height: 662px;
  background-size: 100% auto;
  -webkit-transform: translate(-50%, -49%) scaleY(0.95);
  transform: translate(-50%, -49%) scaleY(0.95);
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .screenshot-active .slick-current .screenshot-item::before {
    width: 240px;
    height: 505px;
    top: 49%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .screenshot-active .slick-current .screenshot-item::before {
    width: 330px;
    top: 49%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .screenshot-active .slick-current .screenshot-item::before {
    display: none;
  }
}
@media (max-width: 767px) {
  .screenshot-active .slick-current .screenshot-item::before {
    display: none;
  }
}
.screenshot-active .slick-dots {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.screenshot-active .slick-dots li {
  display: inline-block;
}
.screenshot-active .slick-dots li button {
  height: 6px;
  width: 6px;
  border: 0;
  font-size: 0px;
  border-radius: 50%;
  background: #e0e0e0;
  padding: 0;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.screenshot-active .slick-dots li.slick-active button {
  background: #2aa38c;
  width: 25px;
  border-radius: 10px;
}

.download-area {
  background: linear-gradient(90deg, #108971 0%, #13bf9e 100%);
  padding-top: 62px;
  padding-bottom: 65px;
}

@media (max-width: 767px) {
  .download-content {
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .download-content {
    text-align: left;
  }
}
.download-content .title {
  color: #fff;
  font-size: 40px;
  line-height: 48px;
}
@media (max-width: 767px) {
  .download-content .title {
    font-size: 32px;
  }
}
.download-content span {
  font-weight: 300;
  color: #fff;
}

@media (max-width: 767px) {
  .download-btn {
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .download-btn {
    text-align: left;
  }
}
.download-btn ul {
  margin: 25px 0 0 70px;
  padding: 0;
  list-style: none;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .download-btn ul {
    margin: 25px 0 0 50px;
  }
}
@media (max-width: 767px) {
  .download-btn ul {
    margin: 25px 0 0 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-btn ul {
    margin: 25px 0 0 0px;
  }
}
.download-btn ul li {
  display: inline-block;
}
.download-btn ul li a {
  background: #fad029;
  height: 67px;
  display: inline-block;
  padding: 13px 26px 0 56px;
  border-radius: 4px;
  position: relative;
}
.download-btn ul li a img {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.download-btn ul li a span {
  display: block;
  font-size: 13px;
  color: #000;
  line-height: 16px;
}
.download-btn ul li a b {
  color: #383838;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.download-btn ul li a.btn-2 {
  background: #025242;
  margin-left: 16px;
}
@media (max-width: 767px) {
  .download-btn ul li a.btn-2 {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .download-btn ul li a.btn-2 {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.download-btn ul li a.btn-2 span {
  color: #e0e0e0;
}
.download-btn ul li a.btn-2 b {
  color: #fff;
}
.download-btn ul li a.btn-2:hover {
  box-shadow: 0px 0px 40px rgba(2, 82, 66, 0.8);
}
.download-btn ul li a:hover {
  box-shadow: 0px 0px 40px rgba(250, 208, 41, 0.8);
}

/*===========================
    21.OUR CASE css 
===========================*/
.our-case-area {
  background: #fff;
  padding-top: 84px;
}
.our-case-area .project-title {
  padding-bottom: 10px;
}
.our-case-area .project-title .title {
  font-family: "Playfair Display", serif;
}
.our-case-area.our-case-15-area {
  padding-top: 86px;
  background: #f5f5f5;
}
.our-case-area.our-case-15-area .case-tab-btn .nav .nav-link {
  background: #fff;
}
.our-case-area.our-case-15-area .case-tab-btn .nav .nav-link .title {
  font-family: "space_grotesk";
}
.our-case-area.our-case-15-area .case-tab-btn .nav .nav-link.active {
  background: #f0323c;
}
.our-case-area.our-case-15-area
  .case-tab-btn
  .nav
  .nav-link.active
  span::before {
  background: #fff;
}
.our-case-area.our-case-15-area .case-tab-btn .nav .nav-link span::before {
  background: #f0323c;
}
.our-case-area.our-case-15-area
  .case-content
  .tab-content
  .item
  .case-thumb
  .case-btn
  a
  span {
  background: #f0323c;
}

.case-tab-btn .nav .nav-link {
  padding: 17px 45px 8px 30px;
  margin: 0;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .case-tab-btn .nav .nav-link {
    padding: 17px 30px 8px 30px;
  }
}
@media (max-width: 767px) {
  .case-tab-btn .nav .nav-link {
    padding: 17px 30px 8px 30px;
  }
}
.case-tab-btn .nav .nav-link span {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  padding-left: 20px;
  position: relative;
  letter-spacing: 1px;
}
.case-tab-btn .nav .nav-link span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 8px;
  width: 8px;
  background: #2aa38c;
}
.case-tab-btn .nav .nav-link .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  padding-top: 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .case-tab-btn .nav .nav-link .title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-tab-btn .nav .nav-link .title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .case-tab-btn .nav .nav-link .title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .case-tab-btn .nav .nav-link .title {
    font-size: 22px;
  }
}
.case-tab-btn .nav .nav-link.active {
  background: #2aa38c;
}
.case-tab-btn .nav .nav-link.active span {
  color: #fff;
}
.case-tab-btn .nav .nav-link.active span::before {
  background: #fff;
}
.case-tab-btn .nav .nav-link.active .title {
  color: #fff;
}
.case-tab-btn.case-tab-btn-11 .nav .nav-link {
  border-radius: 5px;
}
.case-tab-btn.case-tab-btn-11 .nav .nav-link .title {
  font-family: "space_grotesk";
}
.case-tab-btn.case-tab-btn-11 .nav .nav-link span::before {
  background: #4a7aff;
}
.case-tab-btn.case-tab-btn-11 .nav .nav-link.active {
  background: #4a7aff;
}
.case-tab-btn.case-tab-btn-11 .nav .nav-link.active span::before {
  background: #fff;
}

.case-content .tab-content .item .case-thumb {
  position: relative;
}
.case-content .tab-content .item .case-thumb > img {
  width: 100%;
}
.case-content .tab-content .item .case-thumb .case-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.case-content .tab-content .item .case-thumb .case-btn a {
  background: #fff;
  display: inline-block;
  line-height: 52px;
  border-radius: 40px;
  color: #000;
  padding: 0 16px 0 11px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
}
.case-content .tab-content .item .case-thumb .case-btn a span {
  display: inline-block;
  background: #2aa38c;
  height: 30px;
  width: 30px;
  line-height: 27px;
  text-align: center;
  border-radius: 50%;
}
.case-content .tab-content .item .case-text {
  padding-top: 26px;
}

.single-post-area {
  padding-bottom: 100px;
}

.sidebar-search .input-box {
  position: relative;
}
.sidebar-search .input-box input {
  width: 100%;
  height: 66px;
  background: #e0e0e0;
  border-radius: 10px;
  border: 0;
  padding-left: 25px;
  color: #000;
}
.sidebar-search .input-box input::placeholder {
  opacity: 1;
  color: #000;
}
.sidebar-search .input-box button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 66px;
  background: #ffab4a;
  border-radius: 10px;
  border: 0;
  color: #fff;
  font-size: 18px;
  color: #000;
  font-size: 20px;
}

.sidebar-categories .title {
  font-size: 30px;
  font-weight: 600;
  position: relative;
  padding-left: 20px;
}
.sidebar-categories .title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background: #ffab4a;
}
.sidebar-categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-categories ul li a {
  font-size: 20px;
  line-height: 44px;
  color: #000;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar-categories ul li a span {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 28px;
  width: 28px;
  text-align: center;
  line-height: 20px;
  background: #ffab4a;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sidebar-categories ul li a > img {
  padding-right: 15px;
}
.sidebar-categories ul li a:hover {
  color: #ffab4a;
}
.sidebar-categories ul li a:hover span {
  opacity: 1;
}

.sidebar-post > .title {
  font-size: 30px;
  font-weight: 600;
  position: relative;
  padding-left: 20px;
}
.sidebar-post > .title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background: #ffab4a;
}
.sidebar-post .post-items .item {
  display: flex;
  align-items: center;
}
.sidebar-post .post-items .item .thumb {
  min-width: 110px;
}
.sidebar-post .post-items .item .content {
  padding-left: 20px;
}
.sidebar-post .post-items .item .content .title {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar-post .post-items .item .content .title {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .sidebar-post .post-items .item .content .title {
    font-size: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-post .post-items .item .content .title {
    font-size: 20px;
  }
}
.sidebar-post .post-items .item .content span {
  font-size: 14px;
  font-weight: 500;
}

.sidebar-gallery > .title {
  font-size: 30px;
  font-weight: 600;
  position: relative;
  padding-left: 20px;
}
.sidebar-gallery > .title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background: #ffab4a;
}
.sidebar-gallery ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.sidebar-gallery ul li {
  margin-right: 10px;
  margin-top: 10px;
}

.sidebar-newsletter .footer-newsletter-item {
  background: #ffab4a;
}
.sidebar-newsletter .footer-newsletter-item .title {
  color: #000;
}

.single-post-topbar {
  display: flex;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .single-post-topbar {
    display: block;
  }
}
.single-post-topbar ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 767px) {
  .single-post-topbar ul {
    flex-wrap: wrap;
  }
}
.single-post-topbar ul li {
  display: inline-block;
  line-height: 36px;
  background: #f5f5f5;
  padding: 0 26px;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .single-post-topbar ul li {
    padding: 0 10px;
    margin-right: 6px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-post-topbar ul li {
    margin-right: 7px;
  }
}
@media (max-width: 767px) {
  .single-post-topbar ul li {
    margin-top: 5px;
  }
}
.single-post-topbar ul li img {
  margin-right: 8px;
}
.single-post-topbar .social {
  padding-left: 5px;
}
@media (max-width: 767px) {
  .single-post-topbar .social {
    margin-top: 20px;
  }
}
.single-post-topbar .social ul li {
  padding: 0;
}
.single-post-topbar .social ul li a {
  height: 36px;
  width: 36px;
  color: #383838;
  text-align: center;
  border-radius: 50%;
}
.single-post-topbar .social ul li a:hover {
  background: #ffab4a;
  color: #000;
}

.post-thumb {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.post-thumb a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  background: #ffab4a;
  color: #fff;
  border-radius: 50%;
}
.post-thumb span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  left: 20px;
  bottom: 15px;
}
.post-thumb img {
  width: 100%;
}

.post-content {
  color: #383838;
}
.post-content ul {
  margin: 0;
  padding: 50px 0 10px;
  padding-left: 16px;
}
.post-content ul li {
  font-size: 17px;
  font-weight: 400;
  line-height: 35px;
  color: #383838;
}
.post-content ul li:nth-child(2) {
  text-decoration: underline;
}
.post-content .post-quote-item {
  position: relative;
  padding-left: 38px;
  font-size: 18px;
  font-weight: 500;
  padding-right: 7px;
  margin-top: 55px;
}
@media (max-width: 767px) {
  .post-content .post-quote-item {
    font-size: 15px;
    padding-left: 20px;
    padding-right: 0;
  }
}
.post-content .post-quote-item::before {
  position: absolute;
  content: "";
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background: #ffab4a;
}
.post-content .post-quote-item .info {
  padding-left: 65px;
  position: relative;
}
.post-content .post-quote-item .info img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.post-content .post-quote-item .info .title {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.post-content .post-quote-item .info span {
  font-size: 14px;
}

.post-details-item {
  padding-top: 40px;
}
.post-details-item .title {
  font-size: 25px;
  line-height: 30px;
}
.post-details-item .text {
  margin-top: 30px;
}

.post-next {
  border-top: 1px dashed rgba(56, 56, 56, 0.15);
  margin-top: 54px;
}
.post-next .prev-post {
  position: relative;
  padding-left: 20px;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  padding-right: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .post-next .prev-post {
    padding-left: 10px;
    padding-right: 0;
  }
}
.post-next .prev-post::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background: #e0e0e0;
}
.post-next .prev-post span {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffab4a;
  letter-spacing: 1px;
}
.post-next .prev-post a {
  color: #000;
}

.post-form-area {
  padding-top: 90px;
  padding-bottom: 100px;
}

.post-form-title .title {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.post-form-item .input-box input {
  width: 100%;
  background: #f5f5f5;
  border: 0;
  border-radius: 10px;
  padding-left: 25px;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.post-form-item .input-box input::placeholder {
  opacity: 1;
  color: #000;
}
.post-form-item .input-box textarea {
  width: 100%;
  background: #f5f5f5;
  border: 0;
  border-radius: 10px;
  padding-left: 25px;
  height: 130px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  resize: none;
  padding-top: 15px;
}
.post-form-item .input-box textarea::placeholder {
  opacity: 1;
  color: #000;
}
.post-form-item .input-box button {
  background: #ffab4a;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.post-news {
  margin-top: 97px;
}
.post-news .post-news-title .title {
  font-size: 30px;
  font-weight: 600;
}
.post-news .post-news-items .item {
  position: relative;
  margin-left: 70px;
  border-bottom: 1px dashed rgba(56, 56, 56, 0.15);
  color: #000;
}
.post-news .post-news-items .item span {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.post-news .post-news-items .item img {
  position: absolute;
  left: -70px;
  top: 5px;
}
@media (max-width: 767px) {
  .post-news .post-news-items .item.item-2 {
    padding-left: 0;
  }
}
.post-news .post-news-items .item.item-2 img {
  left: -40px;
}
@media (max-width: 767px) {
  .post-news .post-news-items .item.item-2 img {
    left: -70px;
  }
}
.post-news .post-news-items .item a {
  position: absolute;
  right: 0;
  top: 0;
  color: #ffab4a;
}
.post-news .post-news-items .item.item-4 {
  border-bottom: 0;
}

/*# sourceMappingURL=style.css.map */
